import React, { Fragment } from "react";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const CustomBreadCrumb = ({ crumbs }) => {
  let navigate = useNavigate();
  return (
    <div className="crumbs-ctn">
      {crumbs?.map((crumb, index) => (
        <Fragment key={crumb.to}>
          <span
            className={index === crumbs?.length - 1 ? "b-txt" : ""}
            onClick={() => navigate(crumb.to)}
          >
            {crumb.txt}
          </span>
          {index !== crumbs?.length - 1 && (
            <img src="/assets/icons/chevron-right.svg" alt="chevron" />
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default CustomBreadCrumb;
