import React from "react";
import CompanyDetail from "../CompanyDetail";
import { useParams } from "react-router-dom";

const SubscriberListing = () => {
  const { filter } = useParams();

  return (
    <div>
      <CompanyDetail showBreadCrumb={false} filter={filter} />
    </div>
  );
};

export default SubscriberListing;
