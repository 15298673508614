import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import classNames from "classnames";
import { checkAllFieldsVlidation } from "../../utilities/functions/common";
import { parseFormData } from "../../utilities/functions/parsers";
import RSelect from "../RSelect";
import "./styles.scss";

const RForm = forwardRef((props, ref) => {
  const [fList, setList] = useState(props.formList);
  const [isEyeOn, setEyeOn] = useState(false);

  const onChnageEye = () => {
    setEyeOn((prev) => !prev);
    setList((prev) => {
      return {
        ...prev,
        password: {
          ...prev["password"],
          type: !isEyeOn ? "text" : "password",
        },
      };
    });
  };

  const onChnage = (e) => {
    setList((prev) => {
      return {
        ...prev,
        [e.target.name]: { ...prev[e.target.name], value: e.target.value },
      };
    });
  };

  const onChangeCountry = (val) => {
    console.log({ val });
    setList((prev) => {
      return {
        ...prev,
        country: { ...prev["country"], value: val },
      };
    });
  };

  const makefieldsClear = () => {
    let localList = fList;
    Object.keys(fList).forEach((fieldKey) => {
      localList = {
        ...localList,
        [fieldKey]: {
          ...localList[fieldKey],
          value: "",
        },
      };
    });
    setList(localList);
  };

  useImperativeHandle(ref, () => {
    const { isFormForMedia = false } = props;
    return !isFormForMedia ? check : { check, makefieldsClear };
  });

  const check = (e) => {
    let localList = fList;
    Object.keys(fList).forEach((fieldKey) => {
      if (fList[fieldKey].required && fList[fieldKey].value === "") {
        localList = {
          ...localList,
          [fieldKey]: {
            ...localList[fieldKey],
            isRequireError: true,
            isInvalidError: false,
          },
        };
        setList(localList);
      } else if (fList[fieldKey].value !== "") {
        localList = {
          ...localList,
          [fieldKey]: {
            ...localList[fieldKey],
            isRequireError: false,
            isInvalidError: fList[fieldKey].regex(fList[fieldKey]?.value),
          },
        };

        setList(localList);
      }
    });

    if (!checkAllFieldsVlidation(Object.values(localList))) {
      const list = parseFormData(localList);
      props.handleSubmit(list);
    }
  };

  const formHandle = (e) => {
    e.preventDefault();
  };

  return (
    <Form className="r-form" id="c-form" onSubmit={formHandle}>
      {Object.keys(fList)?.map((listKey) => (
        <FormGroup className="form-group" key={fList[listKey].name}>
          <div className="inner-form">
            <Label>{fList[listKey].label}</Label>
            {fList[listKey]?.name !== "country" && (
              <Input
                readOnly={fList[listKey]?.readonly}
                type={fList[listKey].type}
                name={fList[listKey].name}
                disabled={fList[listKey]?.disabled ?? false}
                className={classNames("input-cl", {
                  "field-error-style":
                    fList[listKey].isRequireError ||
                    fList[listKey].isInvalidError,
                })}
                value={fList[listKey].value}
                placeholder={fList[listKey].placeholder}
                onChange={(e) => onChnage(e)}
              />
            )}
          </div>

          {fList[listKey]?.name === "country" && (
            <RSelect
              width="390px"
              isError={
                fList[listKey].isRequireError || fList[listKey].isInvalidError
              }
              value={fList[listKey]?.value}
              showPortal={false}
              isSearchable={true}
              className="input-select-fo"
              onChange={onChangeCountry}
              options={fList[listKey]?.options}
              placeholder={fList[listKey].placeholder}
            />
          )}
          <div className="inner-form">
            {fList[listKey].name === "password" &&
              !fList[listKey]?.disabled && (
                <img
                  src={
                    !isEyeOn
                      ? "/assets/icons/cross-eyeSlash.svg"
                      : "/assets/icons/eye.svg"
                  }
                  onClick={onChnageEye}
                  className={classNames("eye-icon-img", {
                    "field-error":
                      fList[listKey].isRequireError ||
                      fList[listKey].isInvalidError,
                  })}
                  alt="eye-icon"
                />
              )}
            <div className="flex space-between error-d">
              {fList[listKey].isRequireError && (
                <div className="flex">
                  <img
                    src="/assets/icons/exclamation-circle.svg"
                    alt="circle"
                  />
                  <div className="error">{fList[listKey]?.requireError}</div>
                </div>
              )}
              {fList[listKey].isInvalidError && (
                <div className="flex">
                  <img
                    src="/assets/icons/exclamation-circle.svg"
                    alt="circle"
                  />
                  <div className="error">{fList[listKey]?.invalidError}</div>
                </div>
              )}
              {fList[listKey]?.showChangeTxt && (
                <div className="small" onClick={props.handlePassword}>
                  {fList[listKey]?.changeTxt}
                </div>
              )}
            </div>
          </div>
        </FormGroup>
      ))}
    </Form>
  );
});

export default RForm;
