import React, { useEffect, useState } from "react";
import TopBar from "../../hoc/TopBar";
import roleStore from "../../store/role-store";
import { fetchRewardAccess, updateRewardAccess } from "../../apis/apis";
import { throughError } from "../../utilities/functions/common";
import TableBox from "../../components/TableBox";
import {
  parseRewardAccess,
  parserRewardPayload,
} from "../../utilities/functions/parsers";
import {
  dataShapeForRewardAccess,
  headersForRewardAccess,
} from "../../utilities/static";
import CommonModal from "../../components/CommonModal";
import { isUndefined } from "lodash";
import "./styles.scss";
import ConfirmationPopup from "../../components/MediaStatus";
import useStore from "../../store/store";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

const RewardAccess = () => {
  const { loggedUser } = roleStore();
  const [data, setData] = useState({});
  const [isOpen, setModal] = useState(false);
  const [currentRewardType, setRewardType] = useState({});
  const { loading, setLoading } = useStore();
  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getRewardAccess();
    // eslint-disable-next-line
  }, []);

  const getRewardAccess = () => {
    fetchRewardAccess(loggedUser?._id)
      .then((resp) => {
        const pData = parseRewardAccess(resp?.data?.data);
        setData(pData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const updaterewardAccess = (payload) => {
    setLoading(true);
    updateRewardAccess(data?.id, payload)
      .then((resp) => {
        getRewardAccess();
        toggle();
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const toggle = (rewardType) => {
    setRewardType(rewardType);
    setModal((prev) => !prev);
  };

  const handleStatusPopup = () => {
    if (!isUndefined(loggedUser?._id)) {
      const payload = parserRewardPayload(data?.data, currentRewardType);
      updaterewardAccess({ ...payload, agencyId: data?.agencyId });
    }
  };

  return (
    <div className="reward-access">
      <TopBar>
        <h1>Settings</h1>
      </TopBar>

      <div className="inner-ctn">
        <TableBox
          data={dataShapeForRewardAccess(data?.data, toggle)}
          headers={headersForRewardAccess}
          enablePagination={false}
          forRewardAccess={true}
          paginationInfo={{ currentLenghtOfRecord: 8 }}
        />
      </div>

      <CommonModal isOpen={isOpen} toggle={toggle} showCross={false}>
        <ConfirmationPopup
          onClickbtn2={toggle}
          onClickbtn1={handleStatusPopup}
          title={`You want to ${
            currentRewardType?.status ? "in-active" : "active"
          } this reward access.`}
        />
      </CommonModal>

      <Loader loading={loading} />
    </div>
  );
};

export default RewardAccess;
