import React from "react";
import { Container } from "reactstrap";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../Dashboard";
import MediaCompanies from "../MediaCompanies";
import ActivityLog from "../ActivityLog";
import Settings from "../Settings";
import classNames from "classnames";

import Account from "../Account";
import ChangePassword from "../ChangePassword";
import CompanyDetail from "../CompanyDetail";
import Wallet from "../Wallet";
import NotFound from "../NotFound";
import useStore from "../../store/store";
import AgencyDashboard from "../AgencyDashboard";
import "./styles.scss";
import roleStore from "../../store/role-store";
import CommonModal from "../../components/CommonModal";
import ConfirmationPopup from "../../components/MediaStatus";
import { makeFirtLetterCapital } from "../../utilities/functions/common";
import SubscriberListing from "../SubscriberListing";
import IntegrationKeys from "../IntegrationKeys";
import TrendingListing from "../TrendingListing";
import RewardAccess from "../RewardAccess";
import DeveloperGuide from "../DeveloperGuide";

const Content = () => {
  const { sidebarIsOpen, popup, setPopup, popupSrc, currentPopupType } =
    useStore();
  const { userRole } = roleStore();

  return (
    <Container
      fluid
      className={classNames("content", { "is-open": sidebarIsOpen })}
    >
      <Routes>
        <Route
          path="/"
          element={
            userRole === "SUPER_ADMIN" ? <Dashboard /> : <AgencyDashboard />
          }
        />
        <Route path="/companies/:filter?" element={<MediaCompanies />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/account" element={<Account />} />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/integration-keys" element={<IntegrationKeys />} />

        <Route
          path="/company-detail/:cmpid/:compName?"
          element={<CompanyDetail />}
        />
        <Route
          path="/wallet/:crumbCheck/:subscriberId/:subscriberName/:companyId?/:compName?"
          element={<Wallet />}
        />
        <Route path="/activity-log" element={<ActivityLog />} />
        <Route path="/trending-list/:type" element={<TrendingListing />} />
        <Route path="/developer-guide" element={<DeveloperGuide />} />

        <Route
          path="/subscribers-list/:filter?"
          element={<SubscriberListing />}
        />

        <Route path="/reward-access" element={<RewardAccess />} />

        <Route path="*" element={<NotFound />} />
      </Routes>

      <CommonModal isOpen={popup.isError || popup.isSuccess} showCross={false}>
        <ConfirmationPopup
          btn1Text="Ok"
          isTwobtn={false}
          title={popup?.message}
          iconSrc={popupSrc[currentPopupType]}
          onClickbtn1={() => setPopup("")}
          mainHeading={makeFirtLetterCapital(currentPopupType)}
        />
      </CommonModal>
    </Container>
  );
};

export default Content;
