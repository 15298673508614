import React from "react";
import RButton from "../RButton";
import "./styles.scss";
import classNames from "classnames";

const ConfirmationPopup = ({
  iconSrc = "/assets/icons/sureIcon.svg",
  title = "You want to in-active this user.",
  mainHeading = "Are you Sure?",
  btn1Text = "Yes",
  btn2Text = "No",
  isTwobtn = true,
  onClickbtn1,
  onClickbtn2,
  pText = null,
}) => {
  return (
    <div className="confirmation-popup">
      <img src={iconSrc} alt="popup-icon" />
      <div className="medium-upper mt-24">{mainHeading}</div>
      <div className="low mt-8">{title}</div>
      {pText && <div className="p-text mt-16">{pText}</div>}
      <div
        className={classNames("flex mt-40 popup-top", {
          "single-btn": !isTwobtn,
        })}
      >
        <RButton btnText={btn1Text} onClick={onClickbtn1} />
        {isTwobtn && (
          <RButton
            btnText={btn2Text}
            onClick={onClickbtn2}
            className="btn2-style"
          />
        )}
      </div>
    </div>
  );
};

export default ConfirmationPopup;
