import React from "react";
import "./styles.scss";
import { getuserName } from "../../utilities/functions/parsers";
import roleStore from "../../store/role-store";
import { makeImgkeyIntoUrl } from "../../utilities/functions/common";

const UserInfo = () => {
  const { loggedUser, isAdmin } = roleStore();

  return (
    <div className="user-info">
      <img
        src={makeImgkeyIntoUrl(
          isAdmin ? loggedUser?.userImage : loggedUser?.agencyImage
        )}
        alt="user-logo"
      />
      <div className="title-info">
        <h1>{getuserName(loggedUser, isAdmin)}</h1>
        <span className="lowMedium">Welcome back {loggedUser?.firstName}!</span>
      </div>
    </div>
  );
};

export default UserInfo;
