import React from "react";
import Badge from "../Badge";
import "./styles.scss";
import Image from "../Image";

const TrendingArticleBox = ({
  title,
  imgSrc,
  engagement,
  type = "video",
  engagementType,
  isCompany = true,
}) => {
  return (
    <div className="trend-art-box">
      <div className="img-tilte">
        <Image
          // height="36px"
          imgSrc={imgSrc}
          isCompany={false}
          representative={title}
          className="cmp-img"
          defaultSrc={
            type === "video"
              ? "/assets/icons/videoDefault.svg"
              : "/assets/icons/articleDefault.svg"
          }
          isVideoArticle={true}
        />

        <div className="inner-row">
          <span className="m-title three-line-text" title={title}>
            {title}
          </span>
          <div>
            <div className="flex mt-8">
              <Badge
                type={engagementType}
                amount={engagement}
                isCompany={isCompany}
              />
              <span className="low">Engagements</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrendingArticleBox;
