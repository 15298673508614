import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import TopBar from "../../hoc/TopBar";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";
import { uniqWith, isEqual, isEmpty, isUndefined } from "lodash";
import GoldiesBox from "../../components/GoldiesBox";
import { Col, Row } from "reactstrap";
import { fetchWalletDetail } from "../../apis/apis";
import useStore from "../../store/store";
import Loader from "../../components/Loader";
import { parseWalletResponse } from "../../utilities/functions/parsers";
import "./styles.scss";

const Wallet = () => {
  const { subscriberId, subscriberName, crumbCheck, companyId, compName } =
    useParams();
  const { loading, setLoading } = useStore();
  const [walletDetail, setDetail] = useState({});
  const crumbs = {
    admin: [
      {
        to: "/companies",
        txt: "Media Companies",
        active: false,
      },
      {
        to: `/company-detail/${companyId}/${compName}`,
        txt: compName,
        active: false,
      },
      {
        to: "",
        txt: `${subscriberName}'s Wallet`,
        active: false,
      },
    ],
    media: [
      {
        to: "/subscribers-list",
        txt: "Subscribers",
        active: false,
      },
      {
        to: "",
        txt: `${subscriberName}'s Wallet`,
        active: false,
      },
    ],
  };
  useEffect(() => {
    if (!isUndefined(subscriberId)) {
      getWalletsDetail();
    }
    // eslint-disable-next-line
  }, []);

  const getWalletsDetail = () => {
    setLoading(true);
    fetchWalletDetail(subscriberId)
      .then((resp) => {
        console.log({ resp });
        const walletData = parseWalletResponse(resp?.data?.data);
        setDetail(walletData);
        setLoading(false);
      })
      .catch((err) => {
        console.log({ err });
        setLoading(false);
      });
  };

  return (
    <div className="subscriber-wallet">
      <TopBar>
        <div className="">
          <h1> {JSON.parse(crumbCheck) ? "Media Companies" : "Subscribers"}</h1>
          {!isEmpty(walletDetail) && (
            <CustomBreadCrumb
              crumbs={uniqWith(
                crumbs[JSON.parse(crumbCheck) ? "admin" : "media"],
                isEqual
              )}
            />
          )}
        </div>
      </TopBar>

      <div className="inner-ctn">
        <Row>
          {!isEmpty(walletDetail) ? (
            walletDetail?.map((goldie) => (
              <Col
                lg={3}
                key={goldie.count + goldie?.goldiesType + goldie?.goldiesSource}
                className="goldie-col"
              >
                <GoldiesBox {...goldie} showSubCount={false} />
              </Col>
            ))
          ) : (
            <div>No Wallet Found!</div>
          )}
        </Row>
      </div>

      <Loader loading={loading} />
    </div>
  );
};

export default Wallet;
