import React from "react";
import SeeAll from "../SeeAll";
import "./styles.scss";

const CompanyBox = ({
  type,
  title,
  listing,
  navTo,
  showCompany = true,
  extraPadding = false,
  RenderBox = <></>,
  isLastActive = false,
}) => {
  return (
    <div className="company-box">
      <div className="cmp-bx-row">
        <span className="mediumUpper">{title}</span>
        <SeeAll navTo={navTo} />
      </div>

      {listing.slice(0, 4).map((list) => (
        <RenderBox
          {...list}
          type={type}
          showCompany={showCompany}
          extraPadding={extraPadding}
          key={list?.cmpImg + list?.name}
          isLastActive={isLastActive}
        />
      ))}
    </div>
  );
};

export default CompanyBox;
