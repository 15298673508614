import React from "react";
import "./styles.scss";
import Status from "../Status";
import { IntoComma, nFormatter } from "../../utilities/functions/common";
import { imgsSrcOfGoldies } from "../../utilities/static";

const GoldiesBox = ({
  count,
  subscribers,
  goldiesType,
  goldiesSource,
  isComingSoon = false,
  showSubCount = true,
  istypeNotGoldie = false,
}) => {
  return (
    <div className="goldies-box">
      <div className="goldies-row flex space-between">
        <div className="flex-d-colm">
          <span className="sixty-twelve">
            {!istypeNotGoldie && "Goldies on"} {goldiesType}
          </span>
          <span className="goldies-count">{IntoComma(count)}</span>
        </div>
        <img src={imgsSrcOfGoldies[goldiesType]} alt="goldies" />
      </div>

      <div className="flex mt-8">
        <Status
          status={goldiesSource}
          isComingSoon={isComingSoon ? "coming-soon" : ""}
          explicitType={istypeNotGoldie ? "goldiesWallet" : ""}
        />
        {showSubCount && (
          <span className="subs-count">
            {!istypeNotGoldie
              ? `by ${nFormatter(subscribers)} subscribers`
              : subscribers}
          </span>
        )}
      </div>
    </div>
  );
};

export default GoldiesBox;
