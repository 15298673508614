import React from "react";
import "./styles.scss";
import { FormGroup } from "reactstrap";
import { DebounceInput } from "react-debounce-input";

const RSearch = ({ value = "", onChange }) => {
  return (
    <FormGroup className="r-search">
      <DebounceInput
        type="text"
        value={value}
        name="search"
        minLength={1}
        debounceTimeout={500}
        placeholder="Search ..."
        className={`input-search-field`}
        onChange={onChange}
      />
      {value === "" && (
        <img
          src="/assets/icons/searchIcon.svg"
          alt="search-icon"
          className="s-icon"
        />
      )}
    </FormGroup>
  );
};

export default RSearch;
