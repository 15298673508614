import { getColor } from "../static";

export const activityLogs = [
  {
    isCompany: false,
    badgeType: "like",
    name: "CNN",
    amount: 300,
    representative: "Kelly Casper",
    cmpImg: "/assets/imgs/actPerson.png",
  },
  {
    isCompany: false,
    name: "BBC",
    badgeType: "share",
    amount: 22,
    representative: "Ms. Anne Dickinson",
    cmpImg: "/assets/imgs/actPerson.png",
  },
  {
    isCompany: false,
    name: "BNBB",
    badgeType: "comment",
    amount: 300,
    representative: "Kelly Casper",
    cmpImg: "/assets/imgs/actPerson.png",
  },
  {
    isCompany: false,
    name: "WDC",
    badgeType: "watch",
    amount: 3000,
    representative: "Rita Pollich",
    cmpImg: "/assets/imgs/actPerson.png",
  },
];

export const selectStylesForMedia = (width = "148px", isError) => {
  return {
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "13px",
      color: "#7A828A",
      padding: "0px 0px 10px 0px",
    }),
    input: (provided) => ({
      ...provided,
      paddingBottom: 9,
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "6px",
      borderColor: !isError ? "#ededed" : "#f16063",
      "&:hover": {
        borderColor: !isError ? "#ededed" : "#f16063",
      },
      boxShadow: state.isFocused ? "#fa1c3c" : "none",
      width,
      paddingTop: 6,
      maxHeight: 46,
      height: 46,
    }),
    menu: (provided) => ({
      ...provided,
      padding: "14px 12px 10px 12px",
      borderRadius: "8px",
      background: "#FFFFFF",
      outline: "none",
      border: "none",
      boxShadow:
        "0px 0px 1px 0px rgba(50, 50, 71, 0.20), 0px 1px 2px 0px rgba(50, 50, 71, 0.08)",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#fa1c3c" : "",
      borderRadius: "6px",
      padding: "10px 12px",
      backgroundColor: state.isFocused ? "#fa1c3c" : "white",

      ":hover": {
        backgroundColor: "#fa1c3c",
        color: "#fff",
      },
      marginBottom: "5px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "14px",
      color: getColor(state),
    }),
  };
};

export const mockDataForVideosArticles = [
  {
    imgSrc: "/assets/imgs/trending.png",
    title: "Mastering the Art of UI Design: Essent... ",
    publishedDate: "20/03/2023 at 2:00 PM",
    engagementsDistribution: [
      {
        type: "like",
        amount: 3,
      },
      {
        type: "comment",
        amount: 3,
      },
      {
        type: "share",
        amount: 3,
      },
      {
        type: "view",
        amount: 3,
      },
      {
        type: "watch",
        amount: 2,
      },
    ],
    engagementType: "top",
    engagement: 3000,
  },
];
