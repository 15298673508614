export const responsiveOptions = [
  {
    breakpoint: 2000,
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
          borderRadius: 3,
        },
      },
    },
  },
  {
    breakpoint: 1550,
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "40%",
          endingShape: "rounded",
          borderRadius: 3,
        },
      },
    },
  },
  {
    breakpoint: 1200,
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
          borderRadius: 2,
        },
      },
    },
  },
  {
    breakpoint: 992,
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
          borderRadius: 2,
        },
      },
    },
  },
  {
    breakpoint: 500,
    options: {
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          endingShape: "rounded",
          borderRadius: 2,
        },
      },
    },
  },
];
