import React, { Fragment } from "react";
import "./styles.scss";
import { Button, Spinner } from "reactstrap";
import useStore from "../../store/store";

const RButton = ({
  btnText,
  styles,
  onClick,
  type,
  className,
  isSpinner = false,
  formtxt = "",
  disabled = false,
}) => {
  const { loading = false } = useStore();

  return (
    <Button
      className={`btn-style ${className}`}
      style={styles}
      onClick={onClick}
      type={type}
      form={formtxt}
      disabled={disabled}
    >
      {btnText}
      {isSpinner && (
        <Fragment>
          {loading && (
            <Spinner style={{ marginLeft: "15px" }} size="sm" children="" />
          )}
        </Fragment>
      )}
    </Button>
  );
};

export default RButton;
