import React from "react";
import "./styles.scss";

const EmptyListing = () => {
  return (
    <div className="empty-list">
      <img src="/assets/imgs/empty.svg" alt="empty-img" />
      <div className="no-found">There are no records to display.</div>
    </div>
  );
};

export default EmptyListing;
