import { isEmpty } from "lodash";
import { getAlpha2Code } from "../jsons/alpha-2-countries";
import {
  capitalizeWords,
  formatDateTimeForActivityLog,
  makeFirtLetterCapital,
} from "./common";
import LastActive from "../../components/LastActive";
import { getTimeStringByTimeFrame } from "../static";
import { graphParserWithTimeFrame } from "./graphParser";
import moment from "moment";

export const parsingMediaListing = (listing) => {
  if (!isEmpty(listing)) {
    return listing.map((list) => {
      return {
        avatar: list?.agencyImage,
        status: list?.agencyStatus,
        country: list?.country,
        lastActivity: list?.lastActivity,
        agency: list?.mediaCompany,
        engagementType:
          list?.engagements?.totalEngagements >
          list?.engagements?.engagementsAverage
            ? "top"
            : "down",
        engagements: list?.engagements?.overallTotalEngagements,
        representativeName: list?.representativeName,
        subscribers: list?.subscribers,
        agencyId: list?._id,
      };
    });
  }

  return [];
};

export const parseFormData = (data) => {
  let obj = {};
  Object.keys(data)?.forEach((key) => {
    obj[key] = data[key]?.value;
  });

  return obj;
};

export const parseSignleMediaDetail = (data) => {
  return {
    img: data?.agencyImage,
    representiveName: data?.representativeName,
    mediaCompany: data?.mediaCompany,
    country: data?.country,
    email: data?.email,
    password: data?.password,
  };
};

export const parseWorldData = (data) => {
  let tempObj = {};

  !isEmpty(data?.mediaAgencies) &&
    data?.mediaAgencies?.forEach((media) => {
      tempObj[media?.country] = {
        CName: capitalizeWords(media?.country),
        code: getAlpha2Code[capitalizeWords(media?.country)],
        companies: media?.agencies,
        subs: 0,
      };
    });

  !isEmpty(data?.subscribers) &&
    data?.subscribers?.forEach((subs) => {
      if (tempObj[subs?.country]) {
        tempObj[subs?.country] = {
          ...tempObj[subs?.country],
          subs: subs?.subscribers,
        };
      } else
        tempObj[subs?.country] = {
          companies: 0,
          CName: capitalizeWords(subs?.country),
          subs: subs?.subscribers,
          code: getAlpha2Code[capitalizeWords(subs?.country)],
        };
    });

  return tempObj;
};

export const parseWorldDataAgency = (data) => {
  let res = {};

  !isEmpty(data) &&
    data?.forEach((subs) => {
      res[subs?.country] = {
        CName: capitalizeWords(subs?.country),
        code: getAlpha2Code[capitalizeWords(subs?.country)],
        subs: subs?.subscribers,
      };
    });

  return res;
};

const getObjShape = (list) => {
  return {
    goldiesType: makeFirtLetterCapital(list?.reward?.rewardType),
    count: list?.goldies,
    goldiesSource: makeFirtLetterCapital(list?.reward?.rewardCategory),
    subscribers: list?.subscribers,
  };
};

export const getDashboardGoldiesListing = (listing) => {
  let arrObj = {
    VIDEO: [],
    ARTICLE: [],
    REGISTRATION: [],
    REFERRAL: [],
  };

  if (!isEmpty(listing)) {
    listing?.forEach((list) => {
      arrObj[list?.reward?.rewardCategory].push(getObjShape(list));
    });
  }

  return [
    ...arrObj?.VIDEO,
    ...arrObj?.ARTICLE,
    ...arrObj?.REGISTRATION,
    ...arrObj?.REFERRAL,
  ];
};

export const getCompanyListing = (listing, cmptype) => {
  if (!isEmpty(listing)) {
    return listing?.map((list) => {
      return {
        isCompany: true,
        badgeType: cmptype,
        name: list?.mediaCompany,
        topPerforming: true,
        amount: list?.engagements?.overallTotalEngagements ?? 0,
        cmpImg: list?.agencyImage,
        lastActiveTime: <LastActive lastActive={list?.lastActivity} />,
      };
    });
  }

  return [];
};

export const parseActivityLogs = (listing) => {
  if (!isEmpty(listing)) {
    return listing?.map((list) => {
      return {
        isCompany: false,
        avatar: list?.subscriberImage,
        email: list?.email,
        cmpImg: list?.subscriberImage,
        representative: list?.subscriber,
        badgeType: list?.eventType.toLowerCase(),
        company: list?.company,
        earnedGoldies: list?.earnedGoldies,
        name: list?.company,
        amount: list?.earnedGoldies,
        time: formatDateTimeForActivityLog(list?.updatedAt),
      };
    });
  }

  return [];
};

export const parseSettings = (data) => {
  let rewardForVideos = {
    watch: data[0]?.videoWatchReward ?? 0,
    like: data[0]?.videoLikeReward ?? 0,
    share: data[0]?.videoShareReward ?? 0,
    comment: data[0]?.videoCommentReward ?? 0,
  };

  let rewardForArticles = {
    watch: data[0]?.articleViewReward ?? 0,
    like: data[0]?.articleLikeReward ?? 0,
    share: data[0]?.articleShareReward ?? 0,
    comment: data[0]?.articleCommentReward ?? 0,
  };

  let rewardRefReg = {
    referral: data[0]?.userReferralReward,
    register: data[0]?.userRegistrationReward,
  };

  return {
    id: data[0]?._id,
    rewardRefReg,
    rewardForVideos,
    rewardForArticles,
  };
};

export const makeSettingsPayload = (data) => {
  return {
    ownerId: "64b52f29304f9098da93cde0",
    videoWatchReward: +data?.watchVideo ?? 0,
    videoLikeReward: +data?.likeVideo ?? 0,
    videoShareReward: +data?.shareVideo ?? 0,
    videoCommentReward: +data?.commentVideo ?? 0,
    articleViewReward: +data?.watchArticle ?? 0,
    articleLikeReward: +data?.likeArticle ?? 0,
    articleShareReward: +data?.shareArticle ?? 0,
    articleCommentReward: +data?.commentArticle ?? 0,
    userRegistrationReward: +data?.register,
    userReferralReward: +data?.referral,
  };
};

export const parseEarnedGoldies = (listing, timeframe) => {
  const timeString = getTimeStringByTimeFrame[timeframe];
  let obj = {
    VIDEO: [],
    ARTICLE: [],
    REFERRAL: [],
    REGISTRATION: [],
  };
  if (!isEmpty(listing)) {
    listing?.forEach((list) => {
      const { rewardCategory = "" } = list?.goldieDetail;
      const { goldieDetail } = list;
      if (obj[`${rewardCategory}`]) {
        obj[`${rewardCategory}`] = [
          ...obj[`${rewardCategory}`],
          { [timeString]: goldieDetail[timeString], goldies: list?.goldies },
        ];
      }
    });
  }

  const pData = graphParserWithTimeFrame(timeframe, obj);
  return pData ?? [];
};

export const getuserName = (user, isAdmin) => {
  if (isAdmin) {
    return user?.firstName + " " + user?.lastName;
  }
  if (!isAdmin) {
    return user?.representativeName;
  }
};

const parseEngagements = (eng) => {
  if (!isEmpty(eng)) {
    return [
      {
        type: "like",
        amount: eng?.videoLikeEngagements + eng?.articleLikeEngagements,
      },
      {
        type: "share",
        amount: eng?.videoShareEngagements + eng?.articleShareEngagements,
      },
      {
        type: "comment",
        amount: eng?.videoCommentEngagements + eng?.articleCommentEngagements,
      },
      {
        type: "watch",
        amount: eng?.videoWatchEngagements,
      },
      {
        type: "view",
        amount: eng?.articleViewEngagements,
      },
    ];
  } else
    return [
      {
        type: "like",
        amount: 0,
      },
      {
        type: "share",
        amount: 0,
      },
      {
        type: "comment",
        amount: 0,
      },
      {
        type: "watch",
        amount: 0,
      },
      {
        type: "view",
        amount: 0,
      },
    ];
};

export const getSubscribersParse = (listing) => {
  if (!isEmpty(listing)) {
    return listing?.map((list) => {
      return {
        email: list?.email,
        subsId: list?._id,
        status: list?.status,
        country: list?.country,
        lastName: list?.lastName,
        agencyId: list?.agencyId,
        firstName: list?.firstName,
        time: moment(list?.createdAt).format("DD/MM/YYYY [at] h:mm A"),
        subscriberImg: list?.subscriberImage,
        subscriberImagePath: list?.subscriberImagePath,
        earnedGoldies: list?.goldies?.totalGoldies ?? 0,
        engagements: parseEngagements(list?.engagements),
        subsName: list?.firstName + " " + list?.lastName,
      };
    });
  } else return [];
};

export const parseWalletResponse = (data) => {
  return [
    {
      goldiesSource: "Goldies Wallet",
      goldiesType: "Total Earned Goldies",
      istypeNotGoldie: true,
      count: data?.totalGoldies,
    },
    {
      goldiesSource: "Coming Soon",
      goldiesType: "Goldies Balance",
      istypeNotGoldie: true,
      isComingSoon: true,
      count: 0,
    },
    {
      goldiesSource: "Coming Soon",
      goldiesType: "Last Month Credit",
      istypeNotGoldie: true,
      isComingSoon: true,
      count: 0,
    },
    {
      goldiesSource: "Coming Soon",
      goldiesType: "Transfer Money",
      istypeNotGoldie: true,
      isComingSoon: true,
      count: 0,
    },

    {
      goldiesType: "Watch",
      count: data?.videoWatchGoldies ?? 0,
      goldiesSource: "Video",
      subscribers: 80,
    },
    {
      goldiesType: "Like",
      count: data?.videoLikeGoldies,
      goldiesSource: "Video",
      subscribers: 80,
    },
    {
      goldiesType: "Share",
      count: data?.videoShareGoldies,
      goldiesSource: "Video",
      subscribers: 100000,
    },
    {
      goldiesType: "Comment",
      count: data?.videoCommentGoldies,
      goldiesSource: "Video",
      subscribers: 80,
    },

    {
      goldiesType: "View",
      count: data?.articleViewGoldies,
      goldiesSource: "Article",
      subscribers: 9000,
    },
    {
      goldiesType: "Like",
      count: data?.articleLikeGoldies,
      goldiesSource: "Article",
      subscribers: 80,
    },
    {
      goldiesType: "Share",
      count: data?.articleShareGoldies,
      goldiesSource: "Article",
      subscribers: 100000,
    },
    {
      goldiesType: "Comment",
      count: data?.articleCommentGoldies,
      goldiesSource: "Article",
      subscribers: 80,
    },

    {
      goldiesType: "Registration",
      count: data?.userRegistrationGoldies,
      goldiesSource: "Registration",
      subscribers: 80,
    },

    {
      goldiesType: "Referral",
      count: data?.userReferralGoldies,
      goldiesSource: "Referral",
      subscribers: 80,
    },
  ];
};

export const parseMostNotActiveSubscribers = (data) => {
  if (!isEmpty(data)) {
    return data?.map((list) => {
      return {
        avatar: list?.subscriberImage,
        name: list?.firstName + " " + list?.lastName,
        lastActiveTime: <LastActive lastActive={list?.lastActivity} />,
      };
    });
  } else return [];
};

export const parseAgencyKeys = (data) => {
  if (data) {
    return {
      createdAt: moment(data?.updatedAt).format("MMM DD, YYYY"),
      privateKey: data?.privateKey,
      publicKey: data?.publicKey,
    };
  } else {
  }
};

export const parseVideoArticleData = (data, type = "article") => {
  if (!isEmpty(data)) {
    return data?.map((list) => {
      return {
        title: list?.contentTitle || "No title",
        imgSrc: list?.contentImage,
        publishedDate: moment(list?.createdAt).format("DD/MM/YYYY [at] h:mm A"),
        engagementsDistribution: [
          {
            type: "like",
            amount: list?.engagements?.[`${type}LikeEngagements`],
          },
          {
            type: "comment",
            amount: list?.engagements?.[`${type}CommentEngagements`],
          },
          {
            type: "share",
            amount: list?.engagements?.[`${type}ShareEngagements`],
          },
          {
            type: type === "article" ? "view" : "watch",
            amount:
              type === "article"
                ? list?.engagements?.articleViewEngagements
                : list?.engagements?.videoWatchEngagements,
          },
        ],
        engagement: list?.engagements?.totalEngagements,
        engagementType: "top",
      };
    });
  } else return [];
};

export const parseMostEngagedSubscribers = (data) => {
  if (!isEmpty(data)) {
    return data?.map((list) => {
      return {
        lastActiveTime: false,
        avatar: list?.subscriberImage,
        goldieCount: list?.goldies?.totalGoldies ?? 0,
        name: list?.firstName + " " + list?.lastName,
      };
    });
  } else return [];
};

const rewardAccesTypes = {
  articlecommentAccess: "Article Comment",
  articlelikeAccess: "Article Like",
  articleshareAccess: "Article Share",
  articleviewAccess: "Article View",
  videocommentAccess: "Video Comment",
  videolikeAccess: "Video Like",
  videoshareAccess: "Video Share",
  videowatchAccess: "Video Watch",
};

export const parseRewardAccess = (data) => {
  let obj = [];

  if (!isEmpty(data?.article)) {
    Object.keys(data?.article)?.forEach((art) => {
      obj.push({
        type: art,
        subType: "article",
        key: rewardAccesTypes[`article${art}`],
        status: data?.article[art],
      });
    });
  }

  if (!isEmpty(data?.video)) {
    Object.keys(data?.video)?.forEach((art) => {
      obj.push({
        type: art,
        subType: "video",
        key: rewardAccesTypes[`video${art}`],
        status: data?.video[art],
      });
    });
  }

  obj.push({
    type: "register",
    subType: "userRegistrationReward",
    key: "Registration",
    status: data?.userRegistrationReward,
  });
  obj.push({
    type: "referral",
    subType: "userReferralReward",
    key: "Referral",
    status: data?.userReferralReward,
  });

  return {
    data: obj,
    id: data?._id,
    agencyId: data?.agencyId,
  };
};

export const parserRewardPayload = (rewardList, rewardType) => {
  let obj = {
    video: {},
    article: {},
    userReferralReward: {},
    userRegistrationReward: {},
  };

  rewardList?.forEach((reward) => {
    if (reward?.key === rewardType?.key) {
      obj[reward?.subType] = {
        ...obj[reward?.subType],
        [reward?.type]: !rewardType?.status,
      };
    } else {
      obj[reward?.subType] = {
        ...obj[reward?.subType],
        [reward?.type]: reward?.status,
      };
    }
  });

  return {
    ...obj,
    userReferralReward: obj?.userReferralReward?.referral,
    userRegistrationReward: obj?.userRegistrationReward?.register,
  };
};
