import React from "react";
import { ModalBody, Modal } from "reactstrap";
import "./styles.scss";

const CommonModal = ({
  children,
  isOpen,
  toggle,
  showCross = true,
  className,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={`custom-modal ${className}`}
      size="md"
      centered
    >
      <div className="cross-img">
        {showCross && (
          <img
            src="/assets/icons/modalCross.svg"
            alt="modal-cross"
            onClick={toggle}
          />
        )}
      </div>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default CommonModal;
