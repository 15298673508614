import React from "react";
import "./styles.scss";

const ActionBtns = ({
  status,
  canEdit = false,
  id,
  toggle,
  forSubscribers = false,
}) => {
  return (
    <div className="action-btns flex">
      {canEdit && (
        <div className="action" onClick={() => toggle("edit", id)}>
          Edit
        </div>
      )}
      {!forSubscribers && (
        <div
          className="action"
          onClick={() =>
            toggle("status", id, status === "ACTIVE" ? "inactive" : "active")
          }
        >
          {status === "ACTIVE" ? "Inactive" : "Active"}
        </div>
      )}

      {forSubscribers && (
        <div className="action" onClick={toggle}>
          {status === "ACTIVE" ? "Inactive" : "Active"}
        </div>
      )}
    </div>
  );
};

export default ActionBtns;
