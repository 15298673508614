import React, { Fragment } from "react";
import { isEmpty } from "lodash";
import Image from "../Image";
import "./styles.scss";
import { nFormatter } from "../../utilities/functions/common";

const Subscribers = ({ subscribers }) => {
  return (
    <div className="subscribers-list">
      {!isEmpty(subscribers) ? (
        <Fragment>
          {subscribers.slice(0, 3).map((subs, index) => (
            <Fragment key={subs?.firstName}>
              <Image
                height="36px"
                isCompany={true}
                className="overlap-imges"
                name={subs?.firstName + " " + subs?.lastName}
                imgSrc={subs?.subscriberImage}
                styles={{ left: `${index * 22}px` }}
              />
            </Fragment>
          ))}
          {subscribers?.length > 3 && (
            <div className="am-div">{nFormatter(subscribers?.length - 3)}</div>
          )}
        </Fragment>
      ) : (
        "--"
      )}
    </div>
  );
};

export default Subscribers;
