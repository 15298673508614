import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

const SeeAll = ({ navTo }) => {
  let navigate = useNavigate();

  return (
    <span className="see-all" onClick={() => navigate(navTo)}>
      See all
    </span>
  );
};

export default SeeAll;
