import React, { Fragment } from "react";
import { makeImgkeyIntoUrl } from "../../utilities/functions/common";
import ImgWithCharacters from "../ImgWithCharacters";
import { isUndefined } from "lodash";
import "./styles.scss";

const Image = ({
  name,
  imgSrc,
  styles,
  className,
  defaultSrc,
  height = "50px",
  representative,
  isCompany = false,
  isVideoArticle = false,
}) => {
  return (
    <Fragment>
      {!isVideoArticle
        ? isUndefined(imgSrc) && (
            <ImgWithCharacters
              nameStr={isCompany ? name : representative}
              height={height}
            />
          )
        : isUndefined(imgSrc) && (
            <img
              src={defaultSrc}
              className="table-img default-v-a-pl"
              alt="defaultSrc"
            />
          )}

      {!isUndefined(imgSrc) && (
        <Fragment>
          {imgSrc && imgSrc?.startsWith("https") ? (
            <img
              className={`cmp-img ${className}
               ${isVideoArticle ? "for-art-vid" : ""}
              `}
              src={imgSrc}
              alt="cmp-img"
              style={styles}
            />
          ) : (
            <Fragment>
              {imgSrc && imgSrc !== "string" ? (
                <img
                  className={`cmp-img ${className} ${
                    isVideoArticle ? "for-art-vid" : ""
                  }`}
                  src={makeImgkeyIntoUrl(imgSrc)}
                  style={styles}
                  alt="cmp-img-in"
                />
              ) : (
                <Fragment>
                  {!isVideoArticle ? (
                    <ImgWithCharacters
                      height={height}
                      left={styles?.left}
                      nameStr={isCompany ? name : representative}
                    />
                  ) : (
                    <img
                      src={defaultSrc}
                      className="table-img default-v-a-pl"
                      alt="defaultSrc"
                    />
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default Image;
