import React from "react";
import { FormGroup, Input } from "reactstrap";
import "./styles.scss";

const RCheckBox = ({ checked, onClick }) => {
  return (
    <FormGroup check className="custom-formGrp">
      <Input type="checkbox" checked={checked} onClick={onClick} readOnly />
    </FormGroup>
  );
};

export default RCheckBox;
