import React, { Fragment, useEffect, useState } from "react";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import { useLocation, useNavigate } from "react-router-dom";
import sideMenus from "../../utilities/jsons/menu.json";
import agencySideMenus from "../../utilities/jsons/agencyMenus.json";

import {
  parseMenusOnMount,
  setMenusActive,
  subMenusHandle,
} from "../../utilities/functions/common";
import useStore from "../../store/store";
import roleStore from "../../store/role-store";
import "./styles.scss";
import { API_SERVER_URL } from "../../config";

const settingSubMenu = [
  { isActive: false, title: "Integration Keys", to: "/integration-keys" },
  {
    isActive: false,
    title: "Reward Rest Api's",
    to: "/reward-access",
  },
  {
    isActive: false,
    title: "Developer Guide",
    to: "/developer-guide",
  },
];

const SideBar = () => {
  const { sidebarIsOpen, toggleSideBar } = useStore();
  const { userRole, isAdmin } = roleStore();
  const { pathname } = useLocation();
  const [menus, setMenus] = useState(
    userRole === "SUPER_ADMIN" ? sideMenus : agencySideMenus
  );
  const [isOpen, setIsOpen] = useState(false);
  const [subMenus, setSettingsSubMenu] = useState(settingSubMenu);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  let navigate = useNavigate();

  const handleMenus = (menu, to, display) => {
    if (menu !== "Logout") {
      const pMenus = setMenusActive(menus, menu);
      setMenus(pMenus);
      display && navigate(to);
    } else {
      localStorage.clear();
      navigate("/login");
    }
  };

  const handleSubMenus = (title, to) => {
    const pMenus = setMenusActive(subMenus, title);
    setSettingsSubMenu(pMenus);
    if (title !== "Developer Guide") {
      navigate(to);
    } else {
      window.open(`${API_SERVER_URL}/api-developer-guide`, "_blank");
    }
  };

  useEffect(() => {
    if (!isAdmin) {
      const psubMenus = subMenusHandle(subMenus, window.location?.pathname);
      setSettingsSubMenu(psubMenus);
    }
    const pMenus = parseMenusOnMount(menus, window.location?.pathname);
    setMenus(pMenus);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <div className={classNames("sidebar", { "is-open": sidebarIsOpen })}>
      <div className="backdrop" onClick={toggleSideBar}></div>

      <div className="sidebar-header">
        <img
          src="/assets/icons/logo.svg"
          alt="logo"
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
        />
        <img
          src="/assets/icons/Vector.svg"
          alt="vector"
          className="icon"
          onClick={toggleSideBar}
        />
      </div>
      <div className="side-menu flex">
        <Nav vertical className="list-unstyled pb-3 custom-nav">
          {menus.map((men) => (
            <NavItem className="nv-item" key={men.title}>
              {userRole === "MEDIA_AGENCY" && men.title === "Settings" && (
                <Fragment>
                  <div
                    onClick={() => {
                      handleMenus(men.title, men.to, men?.display);
                      toggleDropdown();
                    }}
                    className={classNames("nav-item-div", {
                      "is-active-nav": men.isActive,
                    })}
                  >
                    <img
                      src={men.isActive ? men.activeSrc : men.inactiveSrc}
                      alt="nav-item-icon"
                    />
                    <h6 className={classNames({ "is-active": men.isActive })}>
                      {men.title}
                    </h6>

                    <div className="settings-icon">
                      <img
                        src={
                          !isOpen
                            ? "/assets/icons/settingsDown.svg"
                            : "/assets/icons/settingsUp.svg"
                        }
                        alt="setting-icon"
                      />
                    </div>
                  </div>
                  <div
                    className={classNames("drop-menu", {
                      "menu-open": isOpen,
                    })}
                  >
                    {subMenus?.map((subMenu) => (
                      <NavLink
                        onClick={() =>
                          handleSubMenus(subMenu.title, subMenu.to)
                        }
                        key={subMenu.title}
                      >
                        <div
                          className={classNames("sub-menu", {
                            "sub-menu-active": subMenu.isActive,
                          })}
                        >
                          <h6>{subMenu.title}</h6>
                        </div>
                      </NavLink>
                    ))}
                  </div>
                </Fragment>
              )}
              <NavLink
                onClick={() => handleMenus(men.title, men.to, men?.display)}
                style={{ display: men?.display ? "list-item" : "none" }}
              >
                <div
                  className={classNames("nav-item-div", {
                    "is-active-nav": men.isActive,
                  })}
                >
                  <img
                    src={men.isActive ? men.activeSrc : men.inactiveSrc}
                    alt="nav-item-icon"
                  />
                  <h6 className={classNames({ "is-active": men.isActive })}>
                    {men.title}
                  </h6>
                </div>
              </NavLink>
            </NavItem>
          ))}
        </Nav>
        <div style={{ marginBottom: "30px" }}></div>
      </div>
    </div>
  );
};

export default SideBar;
