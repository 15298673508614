import React from "react";
import moment from "moment";
import { makeFirtLetterCapital } from "../../utilities/functions/common";

const LastActive = ({ lastActive }) => {
  const getLastActiveString = () => {
    const now = moment();
    const activityTime = moment(lastActive);
    const diffDays = now.diff(activityTime, "days");
    let formattedDate;

    if (diffDays === 0) {
      formattedDate = "Today";
    } else if (diffDays === 1) {
      formattedDate = "Yesterday";
    } else if (diffDays <= 7) {
      formattedDate = "Last week";
    } else if (diffDays <= 30) {
      formattedDate = "Last month";
    } else if (diffDays <= 365) {
      formattedDate = "Last year";
    } else {
      formattedDate = "Long ago";
    }

    return formattedDate ?? "--";
  };

  return (
    <span className="low">{makeFirtLetterCapital(getLastActiveString())}</span>
  );
};

export default LastActive;
