import React from "react";
import ReactApexChart from "react-apexcharts";
import "./styles.scss";
import { responsiveOptions } from "./parser";
import { getXAxisData } from "../../utilities/functions/graphParser";

const EarnedGoldieChart = ({ series, timeframe }) => {
  const state = {
    series,
    options: {
      grid: {
        strokeDashArray: [4, 4],
      },

      chart: {
        type: "bar",
        height: 350,
        toolbar: {
          show: false,
        },
      },
      colors: ["#FF92AE", "#4C6FFF", "#F0C017", "#7671FF"],
      legend: {
        position: "top",
        horizontalAlign: "right",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "18%",
          endingShape: "rounded",
          borderRadius: 9,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value?.toString() !== "Infinity" ? value : 0;
          },
          min: 0,
          max: 100,
          style: {
            colors: [],
            fontSize: "12px",
            fontFamily: "inter",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
          },
        },
      },
      xaxis: {
        categories: getXAxisData(timeframe),
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val;
          },
        },
      },

      responsive: responsiveOptions,
    },
  };

  return (
    <div className="earned-chart">
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="bar"
        height={350}
      />
    </div>
  );
};

export default EarnedGoldieChart;
