import "./styles.scss";

const Loader = ({ loading }) => {
  return (
    <>
      {loading && (
        <div className="flex-container overlay">
          <img
            src="/assets/icons/logo.svg"
            style={{ height: "80px" }}
            alt="loader"
          />
        </div>
      )}
    </>
  );
};

export default Loader;
