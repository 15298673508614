import React from "react";
import BadgeWithCount from "../BadgeWithCount";
import Image from "../Image";
import "./styles.scss";

const AgencySubscriberBox = ({
  avatar,
  name,
  goldieCount,
  isLastActive,
  extraPadding,
  lastActiveTime,
}) => {
  return (
    <div className={`agency-subs-box ${extraPadding ? "extra-padding" : ""}`}>
      <div className="img-tilte">
        <Image representative={name} imgSrc={avatar} className="cmp-img" />
        <div className="inner-row">
          <h2>{name}</h2>
          {!isLastActive ? (
            <BadgeWithCount count={goldieCount} badgeText="Goldies" />
          ) : (
            <div className="low" style={{ color: "#7A7A9D" }}>
              Last Active: {lastActiveTime}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AgencySubscriberBox;
