import React from "react";
import "./styles.scss";
import Badge from "../Badge";

const CompanyEngagements = ({ engagements }) => {
  return (
    <div className="flex">
      {engagements?.map((eng) => (
        <Badge border={true} {...eng} isCompany={true} key={eng.type} />
      ))}

      {engagements?.length <= 0 && <span>--</span>}
    </div>
  );
};

export default CompanyEngagements;
