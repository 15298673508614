import React from "react";
import "./styles.scss";
import classNames from "classnames";
import useStore from "../../store/store";

const TopBar = ({ children }) => {
  const { sidebarIsOpen, toggleSideBar } = useStore();

  return (
    <div className={classNames("top-bar", { "is-open": sidebarIsOpen })}>
      <img
        className="toggle-btn"
        src="/assets/icons/Vector.svg"
        alt="vector"
        onClick={toggleSideBar}
      />

      {children}
    </div>
  );
};

export default TopBar;
