import React, { useEffect, useState } from "react";
import TopBar from "../../hoc/TopBar";
import TableBox from "../../components/TableBox";
import {
  dataShapeForActivityLog,
  headersForActivity,
  showFilterOptionsForMedia,
  typeFilterOptionsForActivity,
} from "../../utilities/static";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import { fetchActivityLogs } from "../../apis/apis";
import { parseActivityLogs } from "../../utilities/functions/parsers";
import useStore from "../../store/store";
import Loader from "../../components/Loader";
import { throughError } from "../../utilities/functions/common";
import roleStore from "../../store/role-store";

const ActivityLog = () => {
  let navigate = useNavigate();
  const [showFilter, setShowFilter] = useState(showFilterOptionsForMedia[0]);
  const [typeByFilter, setTypeByFilter] = useState(
    typeFilterOptionsForActivity[0]
  );
  const { loading, setLoading } = useStore();
  const [activityData, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [paginationInfo, setPaginationInfo] = useState({ limit: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const { loggedUser } = roleStore();

  const onPageChange = (data) => {
    let loPage = 1;
    setCurrentPage(data?.selected);
    getActivityLogs(
      showFilter?.value,
      typeByFilter?.value,
      searchValue,
      loPage + +data?.selected
    );

    setPaginationInfo((prev) => {
      return {
        ...prev,
        page: loPage + +data?.selected,
      };
    });
  };

  const getActivityLogs = (show, type, search, page = 1) => {
    const params = { show, type, search, page };
    setLoading(true);
    fetchActivityLogs(params, loggedUser)
      .then((response) => {
        const { count, totalPages, page, data, limit } = response?.data?.data;
        setPaginationInfo({
          count,
          page: +page,
          limit: +limit,
          pageCount: totalPages,
        });
        const parseData = parseActivityLogs(data);
        setData(parseData);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  useEffect(() => {
    getActivityLogs(showFilter?.value, typeByFilter?.value, searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveFilterVal = (value, type) => {
    setCurrentPage(0);
    if (type === "Show") {
      setShowFilter(value);
      getActivityLogs(value?.value, typeByFilter?.value, searchValue);
    } else {
      setTypeByFilter(value);
      getActivityLogs(showFilter?.value, value?.value, searchValue);
    }
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    getActivityLogs(showFilter?.value, typeByFilter?.value, e.target.value);
  };

  const LocalPaginationInfo = {
    currentPage,
    ...paginationInfo,
    currentLenghtOfRecord: activityData?.length,
  };

  return (
    <div className="activity-log">
      <TopBar>
        <div className="">
          <h1>Activity Log</h1>
        </div>
      </TopBar>
      <div className="inner-ctn">
        <TableBox
          selectTwoType="Type"
          searchValue={searchValue}
          optionValue1={showFilter}
          optionValue2={typeByFilter}
          enablePagination={true}
          onPageChange={onPageChange}
          headers={headersForActivity}
          onSearchChange={onSearchChange}
          paginationInfo={LocalPaginationInfo}
          options1={showFilterOptionsForMedia}
          options2={typeFilterOptionsForActivity}
          handleSaveFilterVal={handleSaveFilterVal}
          data={dataShapeForActivityLog(activityData, navigate)}
        />
      </div>
      <Loader loading={loading} />
    </div>
  );
};

export default ActivityLog;
