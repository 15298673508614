import React, { useRef, useState } from "react";
import { Row, Col } from "reactstrap";
import { Navigate, useNavigate } from "react-router-dom";
import useStore from "../../store/store";
import LoginMain from "./LoginMain";
import ForgetPassword from "./ForgetPassword";
import { getAboutMe, loginUser, sendResetPasswordEmil } from "../../apis/apis";
import Loader from "../../components/Loader";
import { throughError } from "../../utilities/functions/common";
import roleStore from "../../store/role-store";
import "./styles.scss";

const Login = () => {
  const { setLoading, loading } = useStore();
  const { setLoggedUser } = roleStore();

  const { setUserRole, checkRole } = roleStore();
  let formRef = useRef(null);
  const loggedIn = localStorage.getItem("XAUTH");
  const [isForgetPassword, setPasswordCheck] = useState(false);
  const [apiError, setApiError] = useState("");
  const [isEmailSent, setEmailSent] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState(null);

  let navigate = useNavigate();
  const handleSubmit = () => {
    setApiError("");
    formRef.current();
  };

  const loggedUser = (data) => {
    loginUser(data)
      .then(async (response) => {
        if (!response?.data?.data?.passwordStatus) {
          localStorage.setItem("XAUTH", response?.data?.data?.access_token);
          let user = await getAboutMe();
          setLoggedUser({
            ...user?.data?.data,
            u_id: response?.data?.data?.user?._id,
          });
          setUserRole(user?.data?.data?.role);
          checkRole();
          navigate("/");
          setLoading(false);
        } else {
          navigate(
            `/reset-password?token=${response?.data?.data?.token}&new=${true}`
          );
          setLoading(false);
        }
      })
      .catch((err) => {
        setApiError(err?.response?.data?.message);
        setLoading(false);
      });
  };

  const requestResetPassword = (payload) => {
    sendResetPasswordEmil(payload)
      .then((response) => {
        setEmailSent(true);
        setLoading(false);
      })
      .catch((err) => {
        throughError(err, navigate);
        setLoading(false);
      });
  };

  const submit = (data) => {
    setLoading(true);
    loggedUser(data);
  };

  const handleForgetPassword = () => {
    setPasswordCheck((prev) => !prev);
    setEmailSent(false);
  };

  if (loggedIn) {
    return <Navigate to={"/"} />;
  }

  const foegetHandle = (data) => {
    setLoading(true);
    setRecoveryEmail(data?.email);
    requestResetPassword(data);
  };

  const localProps = {
    submit,
    formRef,
    apiError,
    isEmailSent,
    handleSubmit,
    foegetHandle,
    recoveryEmail,
    handleForgetPassword,
  };

  return (
    <Row className="sign-row m-0" style={{ background: "#F5F5F5" }}>
      <Col lg={6} md={8} className="cl-1 m-0">
        <img src="/assets/icons/ss.svg" alt="bg-img" className="signIn-img" />
      </Col>
      <Col lg={6} md={4} className="signin-col m-0">
        {!isForgetPassword ? (
          <LoginMain {...localProps} setApiError={setApiError} />
        ) : (
          <ForgetPassword {...localProps} />
        )}
      </Col>
      <Loader loading={loading} />
    </Row>
  );
};

export default Login;
