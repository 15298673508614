import React, { useEffect, useRef } from "react";
import "./styles.scss";
import {
  checkFilesFormat,
  throughError,
} from "../../utilities/functions/common";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { getImgKey } from "../../apis/apis";
import useStore from "../../store/store";
import { API_SERVER_URL } from "../../config";
import { useNavigate } from "react-router-dom";

const ImgUploader = ({ getKeyOfImg, imgKey = null }) => {
  let ref = useRef(null);
  const { setImgError } = useStore();
  let navigate = useNavigate();

  function getImageDimensions(file, callback) {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    img.onload = function () {
      const width = img.width;
      const height = img.height;
      callback(width, height);
    };
  }

  const checkDimensions = (file) => {
    if (file) {
      getImageDimensions(file, (width, height) => {
        if (width > 1000 || height > 1000) {
          setLoading(false);
          setImgError(
            "Image dimensions must be no larger than 1000px wide and tall."
          );
        } else {
          callApi(file);
        }
      });
    }
  };

  const { setLoading } = useStore();
  const uploadFile = async (e) => {
    let validationFailed = false;
    setLoading(true);
    const files = e.target.files;
    if (!isEmpty(files)) {
      validationFailed = await checkFilesFormat([...files]);
    }
    if (validationFailed) {
      setLoading(false);
      return toast.error("This file extension is not allowed.");
    } else checkDimensions(files[0]);

    e.target.value = null;
  };

  const callApi = (file) => {
    let formData = new FormData();
    formData.append("file", file);
    getImgKey(formData)
      .then((resp) => {
        getKeyOfImg(resp?.data?.key);
        setImgError(null);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const handleInput = () => {
    ref.current?.click();
  };

  useEffect(() => {
    return () => setImgError(null);
  }, [setImgError]);

  return (
    <div className="flex space-between uploader-box">
      <input
        type="file"
        onChange={(event) => uploadFile(event)}
        alt="image"
        hidden
        accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PNG"
        ref={ref}
      />
      <div className="flex gap-15">
        <img
          src={
            imgKey
              ? `${API_SERVER_URL}/media/${imgKey}`
              : "/assets/icons/imagePlaceholder.svg"
          }
          alt="img"
          className="p-img"
        />
        <div className="flex-d-colm">
          <span className="note">
            PNG or JPG no bigger than 1000px wide and tall.
          </span>
        </div>
      </div>
      <img
        src="/assets/icons/uploadBtn.svg"
        alt="icon"
        onClick={handleInput}
        className="up-img"
      />
    </div>
  );
};

export default ImgUploader;
