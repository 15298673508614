import React, { useEffect, useState } from "react";
import TopBar from "../../hoc/TopBar";
import RSelect from "../../components/RSelect";
import UserInfo from "../../components/UserInfo";
import CompanyBox from "../../components/CompanyBox";
import BoxHoc from "../../hoc/BoxHoc";
import { Col, Row } from "reactstrap";
import GoldiesBox from "../../components/GoldiesBox";
import EarnedGoldieChart from "../../components/EarnedGoldieChart";
import LineChart from "../../components/LineChart";
import WorldMap from "../../components/WorldMap";
import {
  adminSeries,
  dateFilterOptsDashboard,
  getStartANdEndDate,
} from "../../utilities/static";
import Loader from "../../components/Loader";
import CompanyInnerBox from "../../components/CompanyInnerBox";
import { fetchDashboardStats } from "../../apis/apis";
import { graphParserWithTimeFrame } from "../../utilities/functions/graphParser";
import {
  getCompanyListing,
  getDashboardGoldiesListing,
  parseActivityLogs,
  parseEarnedGoldies,
  parseWorldData,
} from "../../utilities/functions/parsers";
import MediaCompanies from "../MediaCompanies";
import { isEmpty } from "lodash";
import { throughError } from "../../utilities/functions/common";
import roleStore from "../../store/role-store";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  const { loggedUser } = roleStore();
  const [loading, setLoading] = useState(false);
  const [timeframe, setTImeFrame] = useState(dateFilterOptsDashboard[0]);
  const [topPerformCmp, setTopPerformCmp] = useState([]);
  const [lowPerformCmp, setLowPerformCmp] = useState([]);
  const [onBoardingData, setonboardingData] = useState([]);
  const [goldiesList, setGoldiesList] = useState([]);
  const [worldData, setWorldMapData] = useState({});
  const [earnGoldiesData, setEarnedgoldies] = useState([]);
  const [activityLogs, setActivityLogs] = useState([]);
  const isRoleAdmin = loggedUser?.role === "SUPER_ADMIN" ? true : false;

  let navigate = useNavigate();

  const onChangeFilter = (value) => {
    setTImeFrame(value);
    getDashboardStats(value?.value);
  };

  const getDashboardStats = (timeframe) => {
    setLoading(true);
    const queryDates = getStartANdEndDate(timeframe);
    fetchDashboardStats(timeframe, queryDates?.startDate, queryDates?.endDate)
      .then((resp) => {
        const {
          activityLogData,
          goldiesStatsData,
          lowPerformingAgencies,
          highPerformingAgencies,
          rewardCategoryWiseGoldies,
          agencySubscriberOnboardingByDateCycle,
          agencyAndSubscribersOnboardingByCountry,
        } = resp?.data?.data;
        const data = graphParserWithTimeFrame(
          timeframe,
          agencySubscriberOnboardingByDateCycle
        );

        const worldData = parseWorldData(
          agencyAndSubscribersOnboardingByCountry
        );

        const activityLogs = parseActivityLogs(activityLogData?.data);
        const goldiesList = getDashboardGoldiesListing(goldiesStatsData);
        const topPerformingCompanies = getCompanyListing(
          highPerformingAgencies,
          "top"
        );
        const lowPerformingCompanies = getCompanyListing(
          lowPerformingAgencies,
          "down"
        );

        const earnedGoldies = parseEarnedGoldies(
          rewardCategoryWiseGoldies,
          timeframe
        );

        console.log({ activityLogs });

        setEarnedgoldies(earnedGoldies);
        setActivityLogs(activityLogs);
        setTopPerformCmp(topPerformingCompanies);
        setLowPerformCmp(lowPerformingCompanies);
        setGoldiesList(goldiesList);
        setWorldMapData(worldData);
        setonboardingData(data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  useEffect(() => {
    getDashboardStats(timeframe?.value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="dashboard-ctn">
      <TopBar>
        <div className="dasboard-barContent">
          <h1>Dashboard</h1>
          <RSelect
            width="240px"
            value={timeframe}
            showPortal={false}
            onChange={onChangeFilter}
            options={dateFilterOptsDashboard}
          />
        </div>
      </TopBar>

      <div className="inner-ctn">
        {!isEmpty(loggedUser) && <UserInfo loggedUser={loggedUser} />}
        <Row>
          <Col lg={8} md={6} sm={12} className="on-board-col">
            <BoxHoc>
              <div className="mediumUpper">Onboarding</div>

              <LineChart
                timeframe={timeframe?.value}
                series={adminSeries(onBoardingData)}
                colors={["#4C6FFF", "#FF92AE"]}
              />
            </BoxHoc>
          </Col>
          <Col lg={4} md={6} sm={12} className="world-map-col">
            <BoxHoc className="world-map-bx">
              <WorldMap
                roleIsAdmin={isRoleAdmin}
                colors={["#96B1F0", "#EEF3FF"]}
                series={Object.values(worldData)}
              />
            </BoxHoc>
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={4} sm={12} className="c-col">
            <BoxHoc className="shadow-unset">
              <CompanyBox
                navTo="/companies/high"
                listing={topPerformCmp}
                RenderBox={CompanyInnerBox}
                title="Top Performing Companies"
              />
            </BoxHoc>
          </Col>
          <Col lg={4} md={4} sm={12} className="c-col">
            <BoxHoc className="shadow-unset">
              <CompanyBox
                navTo="/companies/low"
                listing={lowPerformCmp}
                RenderBox={CompanyInnerBox}
                title="Low Performing Companies"
              />
            </BoxHoc>
          </Col>
          <Col lg={4} md={4} sm={12} className="c-col">
            <BoxHoc className="shadow-unset">
              <CompanyBox
                navTo="/activity-log"
                listing={activityLogs}
                title="Activity Log"
                RenderBox={CompanyInnerBox}
              />
            </BoxHoc>
          </Col>
        </Row>

        <div className="media-comp-list-dashboard">
          <MediaCompanies enablePagination={false} />
        </div>
        <BoxHoc className="earn-chart">
          <div className="mediumUpper">Earned Goldies</div>
          {!isEmpty(earnGoldiesData) && (
            <EarnedGoldieChart
              series={earnGoldiesData}
              timeframe={timeframe?.value}
            />
          )}
        </BoxHoc>

        <Row className="goldie-row-tc">
          {goldiesList?.map((goldie) => (
            <Col
              lg={3}
              key={goldie?.goldiesSource + goldie?.goldiesType}
              className="goldie-col"
            >
              <GoldiesBox {...goldie} />
            </Col>
          ))}
        </Row>

        <Loader loading={loading} />
      </div>
    </div>
  );
};

export default Dashboard;
