import React from "react";
import "./styles.scss";

const BadgeWithCount = ({ count, badgeText }) => {
  return (
    <div className="badge-w-count">
      <h6>
        {count} {badgeText}
      </h6>
    </div>
  );
};

export default BadgeWithCount;
