import React from "react";
import RForm from "../../components/RForm";
import RButton from "../../components/RButton";
import { signInFields } from "../../utilities/static";
import EmbedMessage from "../../components/EmbedMessage";

const LoginMain = ({
  handleSubmit,
  formRef,
  handleForgetPassword,
  submit,
  apiError,
}) => {
  return (
    <div className="sign-in-box">
      <div className="m-logo">
        <img src="/assets/icons/logo.svg" alt="logo" />
      </div>
      <img src="/assets/icons/signEmo.svg" alt="signIn-icon" className="img" />
      <h1 className="mb-8 mt-32">Welcome back!</h1>
      <div className="lowMedium mb-16">Sign in to your account</div>
      {apiError && <EmbedMessage message={apiError} />}
      <RForm
        ref={formRef}
        formtxt="c-form"
        handleSubmit={submit}
        formList={signInFields}
        handlePassword={handleForgetPassword}
      />
      <RButton
        type="submit"
        formtxt="c-form"
        btnText="Sign in"
        styles={{ marginTop: "10px" }}
        onClick={handleSubmit}
      />
    </div>
  );
};

export default LoginMain;
