import React from "react";
import "./styles.scss";
import { badges } from "../../utilities/static";
import {
  makeFirtLetterCapital,
  nFormatter,
} from "../../utilities/functions/common";

const Badge = ({ type, amount, isCompany, style, border = false }) => {
  return (
    <div
      style={{
        backgroundColor: !border ? badges[type]?.color : "transparent",
        border: border ? "1px solid #E7E7E7" : "",
        ...style,
      }}
      className="cmp-badge"
    >
      <img src={badges[type]?.icon} alt="badge-icon" />
      <h6 style={{ color: badges[type]?.textColor, fontSize: "10px" }}>
        {isCompany
          ? nFormatter(amount)
          : makeFirtLetterCapital(badges[type]?.badgeName)}
      </h6>
    </div>
  );
};

export default Badge;
