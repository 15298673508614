import React, { useEffect, useState } from "react";
import TopBar from "../../hoc/TopBar";
import RSelect from "../../components/RSelect";
import UserInfo from "../../components/UserInfo";
import { Col, Row } from "reactstrap";
import LineChart from "../../components/LineChart";
import WorldMap from "../../components/WorldMap";
import BoxHoc from "../../hoc/BoxHoc";
import {
  agencySeriesLineChart,
  dateFilterOptsDashboard,
  getStartANdEndDate,
} from "../../utilities/static";
import CompanyBox from "../../components/CompanyBox";
import AgencySubscriberBox from "../../components/AgencySubscriberBox";
import TrendingArticleBox from "../../components/TrendingArticleBox";
import CompanyInnerBox from "../../components/CompanyInnerBox";
import EarnedGoldieChart from "../../components/EarnedGoldieChart";
import GoldiesBox from "../../components/GoldiesBox";
import Loader from "../../components/Loader";
import roleStore from "../../store/role-store";
import { isEmpty } from "lodash";
import "./styles.scss";
import { fetchMediaDashboard } from "../../apis/apis";
import {
  getDashboardGoldiesListing,
  parseActivityLogs,
  parseEarnedGoldies,
  parseMostEngagedSubscribers,
  parseMostNotActiveSubscribers,
  parseVideoArticleData,
  parseWorldDataAgency,
} from "../../utilities/functions/parsers";
import { graphParserWithTimeFrame } from "../../utilities/functions/graphParser";
import { throughError } from "../../utilities/functions/common";
import { useNavigate } from "react-router-dom";

const AgencyDashboard = () => {
  const [loading, setLoading] = useState(true);
  const { loggedUser } = roleStore();
  const [timeframe, setTImeFrame] = useState(dateFilterOptsDashboard[0]);
  const [onBoardingData, setonboardingData] = useState([]);
  const [goldiesList, setGoldiesList] = useState([]);
  const [worldData, setWorldMapData] = useState({});
  const [earnGoldiesData, setEarnedgoldies] = useState([]);
  const [mostActiveSubs, setMostActiveSubs] = useState([]);
  const [notActiveSubs, setNotActiveSubs] = useState([]);
  const [activityLogs, setActivityLogs] = useState([]);
  const [articleListing, setTrendingArticle] = useState([]);
  const [videoListing, setTrendingVideo] = useState([]);
  const [topEngageSubs, setTopEngageSubs] = useState([]);

  const isRoleAdmin = loggedUser?.role === "SUPER_ADMIN" ? true : false;
  let navigate = useNavigate();

  useEffect(() => {
    getMediaDashboard(timeframe?.value);
    // eslint-disable-next-line
  }, []);

  const getMediaDashboard = (timeframe) => {
    setLoading(true);
    const queryDates = getStartANdEndDate(timeframe);
    fetchMediaDashboard(
      timeframe,
      queryDates?.startDate,
      queryDates?.endDate,
      loggedUser?._id
    )
      .then((resp) => {
        const {
          activityLogData,
          goldiesStatsData,
          topTrendingVideosData,
          mostActiveSubscribers,
          leastActiveSubscribers,
          topTrendingArticlesData,
          rewardCategoryWiseGoldies,
          topEngagedSubscribersData,
          subscribersOnboardingData,
          subscriberOnboardingByDateCycle,
        } = resp?.data?.data;
        const data = graphParserWithTimeFrame(timeframe, {
          subscriberOnboarding: subscriberOnboardingByDateCycle,
        });
        const worldData = parseWorldDataAgency(subscribersOnboardingData);
        const goldiesList = getDashboardGoldiesListing(goldiesStatsData);
        const earnedGoldies = parseEarnedGoldies(
          rewardCategoryWiseGoldies,
          timeframe
        );
        const mostActive = parseMostNotActiveSubscribers(
          mostActiveSubscribers,
          true
        );
        const notActive = parseMostNotActiveSubscribers(
          leastActiveSubscribers,
          false
        );

        const topEngage = parseMostEngagedSubscribers(
          topEngagedSubscribersData
        );

        const articles = parseVideoArticleData(topTrendingArticlesData);
        const videos = parseVideoArticleData(topTrendingVideosData);
        const activityLogs = parseActivityLogs(activityLogData);

        setEarnedgoldies(earnedGoldies);
        setTopEngageSubs(topEngage);
        setTrendingArticle(articles);
        setTrendingVideo(videos);
        setMostActiveSubs(mostActive);
        setNotActiveSubs(notActive);
        setonboardingData(data);
        setGoldiesList(goldiesList);
        setWorldMapData(worldData);
        setActivityLogs(activityLogs);

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const onChangeFilter = (value) => {
    setGoldiesList([]);
    setTImeFrame(value);
    getMediaDashboard(value?.value);
  };

  return (
    <div className="agency-dashboard">
      <TopBar>
        <div className="agency-barContent">
          <h1>Dashboard</h1>
          <RSelect
            width="240px"
            value={timeframe}
            showPortal={false}
            onChange={onChangeFilter}
            options={dateFilterOptsDashboard}
          />
        </div>
      </TopBar>

      <div className="inner-ctn">
        {!isEmpty(loggedUser) && <UserInfo loggedUser={loggedUser} />}

        <Row>
          <Col lg={8} md={6} sm={12} className="on-board-colAg">
            <BoxHoc>
              <div className="mediumUpper">Onboarding</div>
              <div className="subs-txt flex mt-16">
                <span></span>Subscribers
              </div>
              <LineChart
                timeframe={timeframe?.value}
                series={agencySeriesLineChart(onBoardingData)}
                colors={["#FF92AE"]}
              />
            </BoxHoc>
          </Col>
          <Col lg={4} md={6} sm={12} className="world-map-col">
            <BoxHoc className="world-map-bx">
              <WorldMap
                isAgency={true}
                roleIsAdmin={isRoleAdmin}
                colors={["#FF92AE", "#FFEEF3"]}
                series={Object.values(worldData)}
              />
            </BoxHoc>
          </Col>
        </Row>

        <Row>
          <Col lg={4} md={4} sm={12} className="c-col">
            <BoxHoc className="shadow-unset">
              <CompanyBox
                navTo="subscribers-list/engage"
                extraPadding={true}
                RenderBox={AgencySubscriberBox}
                listing={topEngageSubs}
                title="Top Engaged Subscribers"
              />
            </BoxHoc>
          </Col>
          <Col lg={4} md={4} sm={12} className="c-col">
            <BoxHoc className="shadow-unset">
              <CompanyBox
                type="article"
                navTo="/trending-list/article"
                listing={articleListing}
                RenderBox={TrendingArticleBox}
                title="Top Trending Articles"
              />
            </BoxHoc>
          </Col>
          <Col lg={4} md={4} sm={12} className="c-col">
            <BoxHoc className="shadow-unset">
              <CompanyBox
                type="video"
                navTo="/trending-list/video"
                listing={videoListing}
                RenderBox={TrendingArticleBox}
                title="Top Trending Videos"
              />
            </BoxHoc>
          </Col>
        </Row>

        <Row>
          <Col className="c-col">
            <BoxHoc className="shadow-unset">
              <CompanyBox
                navTo="/subscribers-list/most"
                isLastActive={true}
                extraPadding={true}
                listing={mostActiveSubs}
                RenderBox={AgencySubscriberBox}
                title="Most Active Subscribers"
              />
            </BoxHoc>
          </Col>
          <Col className="c-col">
            <BoxHoc className="shadow-unset">
              <CompanyBox
                navTo="/subscribers-list/not"
                extraPadding={true}
                isLastActive={true}
                RenderBox={AgencySubscriberBox}
                listing={notActiveSubs}
                title="Not Active Subscribers"
              />
            </BoxHoc>
          </Col>
          <Col className="c-col">
            <BoxHoc className="shadow-unset">
              <CompanyBox
                showCompany={false}
                navTo="/activity-log"
                listing={activityLogs}
                title="Activity Log"
                extraPadding={true}
                RenderBox={CompanyInnerBox}
              />
            </BoxHoc>
          </Col>
        </Row>

        <BoxHoc className="earn-chart">
          <div className="mediumUpper">Earned Goldies</div>
          <EarnedGoldieChart
            series={earnGoldiesData}
            timeframe={timeframe?.value}
          />
        </BoxHoc>

        <Row className="goldie-row-tc">
          {goldiesList?.map((goldie) => (
            <Col lg={3} sm={12} key={goldie.count} className="goldie-col">
              <GoldiesBox {...goldie} />
            </Col>
          ))}
        </Row>

        <Loader loading={loading} />
      </div>
    </div>
  );
};

export default AgencyDashboard;
