import React from "react";
import { getRandomIndex } from "../../utilities/functions/common";
import "./styles.scss";

const ImgWithCharacters = ({ nameStr = "dummy", left, height = 36 }) => {
  // const name = nameStr?.slice(0, 2)?.toUpperCase();
  const name = nameStr.match(/(^\S\S?|\s\S)?/g).map(v=>v.trim()).join("").match(/(^\S|\S$)?/g).join("").toLocaleUpperCase()
  return (
    <div
      className="character-circle mediumUpper"
      style={{ ...getRandomIndex(), left, height, width: height }}
    >
      {name}
    </div>
  );
};

export default ImgWithCharacters;
