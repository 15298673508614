import React from "react";
import { API_SERVER_URL } from "../../config";

const DeveloperGuide = () => {
  return (
    <iframe
      src={`${API_SERVER_URL}/api-developer-guide`}
      width="800"
      height="600"
      title={"Developer Guide"}
    />
  );
};

export default DeveloperGuide;
