import React from "react";
import BoxHoc from "../../hoc/BoxHoc";
import Table from "../Table";

import SeeAll from "../SeeAll";
import "./styles.scss";

const TableBox = ({
  width,
  oboardFilter,
  onPageChange,
  searchValue,
  headers = [],
  data = [],
  options1 = [],
  options2 = [],
  optionValue1,
  optionValue2,
  paginationInfo,
  onSearchChange,
  enablePagination,
  handleSaveFilterVal,
  isOnBoardFilter = false,
  forRewardAccess = false,
  selectTwoType = "Sort By",
}) => {
  return (
    <div className="table-box">
      <BoxHoc className="padding-adjust">
        {!enablePagination && !forRewardAccess && (
          <div className="flex space-between">
            <div className="mediumUpper pl-16">Media Companies Onboard</div>
            <SeeAll navTo="/companies" />
          </div>
        )}

        <Table
          width={width}
          data={data}
          headers={headers}
          options1={options1}
          options2={options2}
          {...paginationInfo}
          pageRangeDisplayed={2}
          marginPagesDisplayed={2}
          searchValue={searchValue}
          oboardFilter={oboardFilter}
          onPageChange={onPageChange}
          optionValue1={optionValue1}
          optionValue2={optionValue2}
          selectTwoType={selectTwoType}
          onSearchChange={onSearchChange}
          forRewardAccess={forRewardAccess}
          isOnBoardFilter={isOnBoardFilter}
          enablePagination={enablePagination}
          handleSaveFilterVal={handleSaveFilterVal}
        />
      </BoxHoc>
    </div>
  );
};

export default TableBox;
