import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.scss";
import Image from "../Image";

const CompanyWithSubs = ({
  agency,
  name,
  email,
  avatar = "",
  navigateTo,
  defaultSrc = "",
  isEmailUnder = false,
  isNavigate = true,
  isAgencyShow = false,
  isVideoArticle = false,
}) => {
  let navigate = useNavigate();

  const handleGoTo = () => {
    if (isNavigate) {
      navigate(navigateTo);
    } else navigateTo();
  };

  return (
    <div className="comp-with-subs flex" onClick={handleGoTo}>
      <Image
        height="36px"
        imgSrc={avatar}
        isCompany={false}
        representative={name}
        className="table-img"
        defaultSrc={defaultSrc}
        isVideoArticle={isVideoArticle}
      />
      <div className="flex-d-colm">
        {isAgencyShow && <span className="agency-t">{agency}</span>}
        <span className="sixty-twelve one-line-txt" title={name}>
          {name}
        </span>
        <span className="low-w">{email}</span>
      </div>
    </div>
  );
};

export default CompanyWithSubs;
