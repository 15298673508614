import React, { useEffect, useState } from "react";
import TopBar from "../../hoc/TopBar";
import RButton from "../../components/RButton";
import KeyBox from "../../components/KeyBox";
import { fetchKeys, generateKeys } from "../../apis/apis";
import { throughError } from "../../utilities/functions/common";
import roleStore from "../../store/role-store";
import { parseAgencyKeys } from "../../utilities/functions/parsers";
import useStore from "../../store/store";
import Loader from "../../components/Loader";
import { isEmpty } from "lodash";
import CommonModal from "../../components/CommonModal";
import ConfirmationPopup from "../../components/MediaStatus";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

const IntegrationKeys = () => {
  const [keysData, setKeys] = useState({});
  const [isOpen, setModal] = useState(false);
  const { loggedUser } = roleStore();
  const { loading, setLoading, setCurrentPopupType, setPopup } = useStore();

  let navigate = useNavigate();
  const generateKey = () => {
    setKeys({});
    createKeys();
  };

  useEffect(() => {
    getKeyPair();
    // eslint-disable-next-line
  }, []);

  const getKeyPair = () => {
    setLoading(true);
    fetchKeys(loggedUser?._id)
      .then((resp) => {
        const { data } = resp?.data;
        const parseKeys = parseAgencyKeys(data);
        setKeys(parseKeys);
        setLoading(false);
      })
      .catch((err) => {
        throughError(err, navigate);
        setLoading(false);
      });
  };

  const createKeys = () => {
    setLoading(true);
    generateKeys(loggedUser?._id)
      .then((resp) => {
        const { data } = resp?.data;
        const parseKeys = parseAgencyKeys(data);
        setKeys(parseKeys);
        setLoading(false);
        setModal(false);
        setCurrentPopupType("successfully");
        setPopup("Keys has been generated.");
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  return (
    <div className="integration-keys">
      <TopBar>
        <div className="flex space-between top-br">
          <h1>Settings</h1>

          {!isEmpty(keysData) && (
            <RButton
              btnText="Generate New Keys"
              styles={{ width: "200px" }}
              onClick={() => setModal((prev) => !prev)}
            />
          )}
        </div>
      </TopBar>

      <div className="inner-ctn">
        {isEmpty(keysData) && !loading && (
          <div className="empty-key-ctn">
            <img src="/assets/icons/key.svg" alt="keys-img" />
            <RButton
              btnText="Generate Keys"
              onClick={() => generateKey()}
              styles={{ width: "140px", marginTop: "70px" }}
            />
          </div>
        )}

        {!isEmpty(keysData) && (
          <div className="flex key-boxes">
            <KeyBox
              keyType="Public"
              value={keysData?.publicKey}
              createdAt={keysData?.createdAt}
            />
            <KeyBox
              keyType="Private"
              value={keysData?.privateKey}
              createdAt={keysData?.createdAt}
            />
          </div>
        )}
      </div>

      <CommonModal
        isOpen={isOpen}
        showCross={false}
        toggle={() => setModal((prev) => !prev)}
      >
        <ConfirmationPopup
          onClickbtn1={generateKey}
          title="You want to generate the new keys."
          onClickbtn2={() => setModal((prev) => !prev)}
          pText="Please note that generating new keys will overwrite the previous public and private keys"
        />
      </CommonModal>

      <Loader loading={loading} />
    </div>
  );
};

export default IntegrationKeys;
