const checkPasswordLength = (password) => {
  return password?.length < 8;
};
export const resetPasswordFields = {
  newPassword: {
    isInvalidError: false,
    name: "newPassword",
    error: "",
    required: true,
    type: "password",
    value: "",
    isRequireError: false,
    label: "New Password",
    placeholder: "New Password",
    invalidError: "Must be 8 characters at least.",
    requireError: "Enter your new password",
    regex: checkPasswordLength,
  },

  confirmPassword: {
    invalidError: "Must be 8 characters at least.",
    value: "",
    required: true,
    label: "Confirm Password",
    isRequireError: false,
    error: "",
    placeholder: "Confirm Password",
    isInvalidError: false,
    name: "confirmPassword",
    requireError: "Enter your confirm password",
    regex: checkPasswordLength,
    type: "password",
  },
};
