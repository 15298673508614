import React, { Fragment } from "react";
import Badge from "../Badge";
import Image from "../Image";
import "./styles.scss";

const CompanyInnerBox = ({
  cmpImg,
  lastActiveTime,
  name,
  badgeType,
  isCompany,
  amount,
  extraPadding = false,
  showCompany = true,
  representative = "",
}) => {
  return (
    <div className={`cmp-inner-box ${extraPadding ? "extra-padding" : ""}`}>
      <div className="img-tilte">
        <Image
          isCompany={isCompany}
          name={name}
          representative={representative}
          imgSrc={cmpImg}
        />
        <div className="inner-row">
          <span className="low" style={{ color: "#7A7A9D" }}>
            {isCompany ? (
              <span className="low last-active">
                Last Active: {lastActiveTime}
              </span>
            ) : (
              <Fragment>
                {showCompany && (
                  <span className="low" style={{ color: "#7A7A9D" }}>
                    {name}
                  </span>
                )}
              </Fragment>
            )}
          </span>
          <h2>{isCompany ? name : representative}</h2>
          <div>
            <div className="flex">
              <Badge type={badgeType} amount={amount} isCompany={isCompany} />
              <span className="low">
                {isCompany ? "Engagements" : `${amount} Goldies`}{" "}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInnerBox;
