import React, { useEffect, useRef, useState } from "react";
import TableBox from "../../components/TableBox";
import TopBar from "../../hoc/TopBar";
import RButton from "./../../components/RButton";
import {
  headersForMediaPage,
  makeDataForMediaAgency,
  showFilterOptionsForMedia,
  typeFilterOptionsForMedia,
} from "../../utilities/static";
import CommonModal from "../../components/CommonModal";
import AddMediaCompany from "../../components/AddMediaCompany";
import {
  addMediaAgency,
  fetchMediaAgencyListing,
  getMediaAgencyById,
} from "../../apis/apis";
import {
  parseSignleMediaDetail,
  parsingMediaListing,
} from "../../utilities/functions/parsers";
import { isEmpty, isUndefined } from "lodash";
import ConfirmationPopup from "../../components/MediaStatus";
import Loader from "../../components/Loader";
import { getError, throughError } from "../../utilities/functions/common";
import EmbedMessage from "../../components/EmbedMessage";
import useStore from "../../store/store";
import { useNavigate, useParams } from "react-router-dom";
import "./styles.scss";

const filterIndex = {
  high: 2,
  low: 3,
};

const MediaCompanies = ({ enablePagination = true }) => {
  const { setLoading, loading, setImgError } = useStore();
  const { filter } = useParams();
  const [showFilter, setShowFilter] = useState(showFilterOptionsForMedia[0]);
  const [sortByFilter, setSortByFilter] = useState(
    isUndefined(filter)
      ? typeFilterOptionsForMedia[0]
      : typeFilterOptionsForMedia[filterIndex[filter] ?? 0]
  );
  const [mediaListing, setMediaListing] = useState([]);
  const [isEmbedMessage1, setEmbedMessage1] = useState(<></>);
  const [mediaDetail, setCurrentMedia] = useState({});
  const [statusPopup, setStatusPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [isMediaDetail, setMediaDetailPresent] = useState(false);
  const [status, setStatus] = useState("ACTIVE");
  const [mediaId, setMediaId] = useState(null);
  const [isOpen, setModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [paginationInfo, setPaginationInfo] = useState({ limit: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const [imgKey, setImgUrl] = useState(null);
  let navigate = useNavigate();

  let formRef = useRef();

  const onPageChange = (data) => {
    let loPage = 1;
    setCurrentPage(data?.selected);
    getMediaListing(
      sortByFilter?.value,
      showFilter?.value,
      searchValue,
      loPage + +data?.selected
    );

    setPaginationInfo((prev) => {
      return {
        ...prev,
        page: loPage + +data?.selected,
      };
    });
  };

  useEffect(() => {
    getMediaListing(sortByFilter?.value, showFilter?.value, searchValue);
    // eslint-disable-next-line
  }, []);

  const getMediaAgencyIdDetail = (id) => {
    setMediaDetailPresent(true);
    getMediaAgencyById(id)
      .then((resposne) => {
        setImgUrl(resposne?.data?.data?.agencyImage);
        setCurrentMedia(parseSignleMediaDetail(resposne?.data?.data));
        setLoading(false);
        setMediaDetailPresent(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const handleActionType = (type, mediaId, status) => {
    setLoading(true);
    setMediaId(mediaId ?? null);
    getMediaAgencyIdDetail(mediaId);

    if (type === "edit") {
      setEditPopup(true);
      toggle(mediaId, false);
    } else if (type === "status") {
      setStatus(status?.toUpperCase());
      toggleHandleStatus();
    }
  };

  const toggle = (id, makeIdNull = true) => {
    setEmbedMessage1(<></>);
    setMediaId(makeIdNull ? null : id);
    setModalOpen((prev) => !prev);
    setEditPopup(!makeIdNull);
    setCurrentMedia(makeIdNull ? {} : mediaDetail);
    setImgUrl(makeIdNull ? null : imgKey);
  };

  const getMediaFormData = (data, makeToggleoAndToast = true) => {
    if (!imgKey) {
      setImgError("Image is required.");
    } else {
      const payload = {
        agencyImage: imgKey,
        agencyImagePath: imgKey,
        representativeName: data?.representiveName,
        mediaCompany: data?.mediaCompany,
        country: !statusPopup ? data?.country?.value : data?.country,
        email: data?.email,
        agencyStatus: status,
      };

      handleAddMediaAgency(payload, makeToggleoAndToast);
    }
  };

  const handleAddMediaAgency = (payload, makeToggle) => {
    setLoading(true);
    addMediaAgency(payload, mediaId)
      .then((response) => {
        getMediaListing();
        setCurrentMedia({});
        setImgUrl(null);
        makeToggle &&
          setEmbedMessage1(
            <EmbedMessage
              messageTypeSuccess={true}
              icon="/assets/icons/greenIcon.svg"
              message={
                !editPopup
                  ? "Media company has been added."
                  : "Your changes have been saved."
              }
            />
          );
        // setEditPopup(false);
        setLoading(false);
        formRef?.current?.makefieldsClear();
      })
      .catch((err) => {
        setEmbedMessage1(<EmbedMessage message={getError(err)} />);
        setLoading(false);
      });
  };

  const getMediaListing = (sortBy, show, search = "", page = 1) => {
    const params = { sortBy, show, search, page };
    setLoading(true);

    fetchMediaAgencyListing(params)
      .then((response) => {
        const { count, totalPages, page, mediaAgencies, limit } =
          response?.data?.data;
        setPaginationInfo({
          count,
          page: +page,
          limit: +limit,
          pageCount: totalPages,
        });

        const pList = parsingMediaListing(mediaAgencies);
        setMediaListing(makeDataForMediaAgency(pList, handleActionType));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const handleStatusPopup = () => {
    if (!isEmpty(mediaDetail)) {
      getMediaFormData(mediaDetail, false);
      toggleHandleStatus();
    }
  };

  const toggleHandleStatus = () => {
    setStatusPopup((prev) => !prev);
  };

  const handleSaveFilterVal = (value, type) => {
    setCurrentPage(0);
    if (type === "Show") {
      setShowFilter(value);
      getMediaListing(sortByFilter?.value, value?.value, searchValue);
    } else {
      setSortByFilter(value);
      getMediaListing(value?.value, showFilter?.value, searchValue);
    }
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    getMediaListing(sortByFilter?.value, showFilter?.value, e.target.value);
  };

  const LocalPaginationInfo = {
    currentPage,
    ...paginationInfo,
    currentLenghtOfRecord: mediaListing?.length,
  };

  const getKeyOfImg = (key) => {
    setImgUrl(key);
  };

  return (
    <div className="media-companies">
      {enablePagination && (
        <TopBar>
          <div className="flex space-between media-cmp-row">
            <h1>Media Companies</h1>
            <RButton
              btnText="Add Media Company"
              styles={{ width: "190px" }}
              onClick={() => toggle(true)}
            />
          </div>
        </TopBar>
      )}
      <div className="media-inner">
        <TableBox
          data={mediaListing}
          searchValue={searchValue}
          optionValue1={showFilter}
          onPageChange={onPageChange}
          optionValue2={sortByFilter}
          headers={headersForMediaPage}
          onSearchChange={onSearchChange}
          paginationInfo={LocalPaginationInfo}
          options1={showFilterOptionsForMedia}
          options2={typeFilterOptionsForMedia}
          enablePagination={enablePagination}
          handleSaveFilterVal={handleSaveFilterVal}
        />
      </div>
      {!isMediaDetail && (
        <CommonModal
          isOpen={isOpen}
          toggle={() => toggle(false)}
          className="for-media-popup"
        >
          <AddMediaCompany
            imgKey={imgKey}
            formRef={formRef}
            editPopup={editPopup}
            getKeyOfImg={getKeyOfImg}
            mediaDetail={mediaDetail}
            isEmbedMessage={isEmbedMessage1}
            getMediaFormData={getMediaFormData}
          />
        </CommonModal>
      )}
      <CommonModal
        isOpen={statusPopup}
        toggle={toggleHandleStatus}
        showCross={false}
      >
        <ConfirmationPopup
          onClickbtn2={toggleHandleStatus}
          onClickbtn1={handleStatusPopup}
          title={`You want to ${
            status === "ACTIVE" ? "active" : "in-active"
          } this user.`}
        />
      </CommonModal>
      {!isOpen && <Loader loading={loading} />}{" "}
    </div>
  );
};

export default MediaCompanies;
