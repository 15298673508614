import React from "react";
import "./styles.scss";

const EmbedMessage = ({
  message,
  icon = "/assets/icons/IconRed.svg",
  icon2 = "/assets/icons/greenIcon.svg",
  messageTypeSuccess = false,
}) => {
  return (
    <div
      className="embed-message flex"
      style={
        messageTypeSuccess
          ? { backgroundColor: "#66CB9F" }
          : { backgroundColor: "#f16063" }
      }
    >
      <img src={messageTypeSuccess ? icon2 : icon} alt="embed-icon" />
      <span className="low">{message}</span>
    </div>
  );
};

export default EmbedMessage;
