import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { IntoComma } from "../../utilities/functions/common";
import { isUndefined } from "lodash";
import geoJson from "../../utilities/jsons/geo.json";
import classNames from "classnames";
import "./styles.scss";

const WorldMap = ({ colors, series, roleIsAdmin, isAgency = false }) => {
  const highlightedCountries = series;
  const [tooltipContent, setTooltipContent] = useState(null);
  const handleMouseEnter = (event, geography) => {
    const highlightedCountry = highlightedCountries.find(
      (country) => country?.CName === geography?.properties?.name
    );
    if (highlightedCountry) {
      setTooltipContent(highlightedCountry);
    }
  };

  const handleMouseLeave = () => {
    setTooltipContent(null);
  };

  return (
    <div className="world-map-ctn">
      <div className="mediumUpper mb-8">Users</div>
      <div className="flex mb-24 mt-16">
        {roleIsAdmin && (
          <div className="flex">
            <div className="circle"></div>
            <span className="leg-title mr-8">Media Companies </span>
          </div>
        )}
        <div className="flex">
          <div className="circle-1"></div>
          <div className="leg-title">Subscribers</div>
        </div>
      </div>
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{ scale: 80 }}
        width={500}
        height={350}
      >
        <Geographies geography={geoJson}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const isHighlighted = highlightedCountries.some(
                (country) => country?.CName === geo?.properties?.name
              );
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  className={isHighlighted ? "highlight-area" : ""}
                  fill={isHighlighted ? colors[0] : colors[1]}
                  stroke={isHighlighted ? colors[0] : colors[1]}
                  onMouseEnter={(e) => handleMouseEnter(e, geo)}
                  onMouseLeave={handleMouseLeave}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      {tooltipContent && (
        <div
          className={classNames("tool-text-box", {
            "agency-tool-box": isAgency,
          })}
        >
          <div className="flex space-between">
            {!isUndefined(tooltipContent?.companies) && (
              <div className="ind-cn flex mr-8">
                <span className="circle"></span>
                <span>{IntoComma(tooltipContent?.companies)}</span>
              </div>
            )}
            <div className="ind-cn flex">
              <span className="circle-1"></span>
              <span>{IntoComma(tooltipContent?.subs)}</span>
            </div>
          </div>
          <div
            className="country-name"
            style={{ color: isAgency ? "#FF92AE" : "" }}
          >
            {tooltipContent?.CName}
          </div>
        </div>
      )}
    </div>
  );
};

export default WorldMap;
