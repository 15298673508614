import React, { useEffect, useRef, useState } from "react";
import BoxHoc from "../../hoc/BoxHoc";
import RForm from "../../components/RForm";
import {
  fieldsForRefferal,
  fieldsForRewardConfiguration,
} from "../../utilities/static";
import TopBar from "../../hoc/TopBar";
import RButton from "../../components/RButton";
import { fecthSettings, saveSettings } from "../../apis/apis";
import {
  makeSettingsPayload,
  parseSettings,
} from "../../utilities/functions/parsers";
import Loader from "../../components/Loader";
import { isEmpty } from "lodash";
import { throughError } from "../../utilities/functions/common";
import useStore from "../../store/store";
import { useNavigate } from "react-router-dom";
import "./styles.scss";

const Settings = () => {
  const [settingsData, setData] = useState({});
  const [finalPayload, setPayload] = useState({});
  const { loading, setLoading, setCurrentPopupType, setPopup } = useStore();

  let videosRef = useRef(null);
  let articlesRef = useRef(null);
  let referral = useRef(null);

  let navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    getSettings();
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    videosRef.current();
    articlesRef.current();
    referral.current();
  };

  const submit = (data) => {
    setPayload((prev) => {
      return { ...prev, ...data };
    });
  };

  const saveSetting = (payload) => {
    saveSettings(settingsData?.id, payload)
      .then((response) => {
        setLoading(false);
        setCurrentPopupType("successfully");
        setPopup("Your changes has been saved.");
      })
      .catch((err) => {
        setLoading(false);
        setPopup("Reward must be greater than 0");
        throughError(err, navigate);
      });
  };

  const makeApiCall = () => {
    if (Object.keys(finalPayload)?.length >= 10) {
      setLoading(true);
      const payload = makeSettingsPayload(finalPayload);
      saveSetting(payload);
      setPayload({});
    }
  };

  useEffect(() => {
    if (!isEmpty(finalPayload)) {
      makeApiCall();
    }
    // eslint-disable-next-line
  }, [finalPayload]);

  const getSettings = () => {
    fecthSettings()
      .then((response) => {
        const pSettings = parseSettings(response?.data?.data?.data);
        setData(pSettings);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  return (
    <div className="settings-container">
      <TopBar>
        <div className="settings-barContent">
          <h1>Settings</h1>
        </div>
      </TopBar>

      <div className="inner-ctn">
        <BoxHoc>
          <div className="mediumUpper">Reward Configuration for Videos</div>

          {!isEmpty(settingsData) && (
            <RForm
              formList={fieldsForRewardConfiguration(
                settingsData?.rewardForVideos,
                "Video"
              )}
              ref={videosRef}
              handleSubmit={submit}
            />
          )}
        </BoxHoc>

        <BoxHoc>
          <div className="mediumUpper">Reward Configuration for Articles</div>

          {!isEmpty(settingsData) && (
            <RForm
              formList={fieldsForRewardConfiguration(
                settingsData?.rewardForArticles,
                "Article"
              )}
              ref={articlesRef}
              handleSubmit={submit}
            />
          )}
        </BoxHoc>

        <BoxHoc className="ref-reg">
          <div className="mediumUpper">Other Rewards Configuration</div>
          {!isEmpty(settingsData) && (
            <RForm
              formList={fieldsForRefferal(settingsData?.rewardRefReg)}
              ref={referral}
              handleSubmit={submit}
            />
          )}
        </BoxHoc>

        <div className="save-btn">
          <RButton
            type="submit"
            btnText="Save"
            styles={{ marginTop: "30px" }}
            onClick={handleSubmit}
          />
        </div>
      </div>

      <Loader loading={loading} />
    </div>
  );
};

export default Settings;
