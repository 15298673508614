import React, { useEffect, useState } from "react";
import RButton from "../RButton";
import BoxHoc from "../../hoc/BoxHoc";
import RForm from "../RForm";
import { keyFields } from "../../utilities/static";
import { toast } from "react-toastify";
import clipboardCopy from "clipboard-copy";
import { isEmpty } from "lodash";
import "./styles.scss";

const KeyBox = ({ keyType, value, createdAt }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(keyFields(keyType, value));
  }, [value, keyType]);

  const handleCopyClick = () => {
    clipboardCopy(value)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <BoxHoc className="key-box">
      <div className="flex space-between">
        <div className="flex-d-colm">
          <span className="sixty-twelve">Key generated on</span>
          <span className="date">{createdAt}</span>
        </div>
        <RButton
          onClick={handleCopyClick}
          styles={{ width: "113px" }}
          btnText={!isCopied ? "Copy Key" : "Copied"}
        />
      </div>
      {!isEmpty(list) && <RForm formList={list} />}
    </BoxHoc>
  );
};

export default KeyBox;
