import React from "react";
import "./styles.scss";
import { getFirstWordUpperOFSetence } from "../../utilities/functions/common";
const Status = ({ status, explicitType, isComingSoon }) => {
  const sStatus = status?.toLowerCase();
  return (
    <div className={`status-bage ${sStatus} ${explicitType} ${isComingSoon}`}>
      {getFirstWordUpperOFSetence(sStatus)}
    </div>
  );
};

export default Status;
