import moment from "moment";
import ActionBtns from "../../components/ActionBtns";
import Badge from "../../components/Badge";
import CompanyEngagements from "../../components/CompanyEngagements";
import CompanyWithSubs from "../../components/CompanyWithSubs";
import LastActive from "../../components/LastActive";
import Status from "../../components/Status";
import Subscribers from "../../components/Subscribers";
import { IntoComma, makeFirtLetterCapital } from "../functions/common";
import dataForMedia from "../jsons/table.json";
import countries from "../../utilities/jsons/countries.json";

export const badges = {
  top: {
    color: "#DEFFEE",
    isInt: true,
    textColor: "#66CB9F",
    icon: "/assets/icons/arrowUp.svg",
  },
  down: {
    color: "#FFE6E4",
    isInt: true,
    textColor: "#F16063",
    icon: "/assets/icons/arrowDown.svg",
  },
  like: {
    color: "#DEFFEE",
    isInt: false,
    textColor: "#66CB9F",
    badgeName: "Like",
    icon: "/assets/icons/like.svg",
  },
  share: {
    color: "#DCF9FF",
    isInt: false,
    badgeName: "Share",
    textColor: "#38CEEC",
    icon: "/assets/icons/share.svg",
  },
  comment: {
    color: "#FFE6DD",
    isInt: false,
    badgeName: "Comment",
    textColor: "#FF7A4D",
    icon: "/assets/icons/comment.svg",
  },
  watch: {
    color: "#FFE6ED",
    isInt: false,
    badgeName: "Watch",
    textColor: "#F0537F",
    icon: "/assets/icons/watch.svg",
  },
  view: {
    color: "#FFE6ED",
    isInt: false,
    badgeName: "View",
    textColor: "#F0537F",
    icon: "/assets/icons/viewEye.svg",
  },
  registration: {
    color: "#E7E7FF",
    isInt: false,
    badgeName: "Registration",
    textColor: "#7671FF",
    icon: "/assets/icons/registeration.svg",
  },
  referral: {
    color: "#FBF1CC",
    isInt: false,
    badgeName: "Referral",
    textColor: "#D9AF1A",
    icon: "/assets/icons/referral.svg",
  },
};

export const headersForMedia = [
  {
    Header: "REPRESENTATIVE",
    accessor: "representative",
  },
  {
    Header: "SUBSCRIBERS",
    accessor: "subscribers",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },

  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "action",
  },
];

export const headersForCompany = [
  {
    Header: "SUBSCRIBERS",
    accessor: "subscriber",
  },

  {
    Header: "TIME",
    accessor: "time",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },

  {
    Header: "EARNED GOLDIES",
    accessor: "earnedGoldies",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },
  {
    Header: "ENGAGEMENTS DISTRIBUTION",
    accessor: "engagements",
  },
  {
    Header: "",
    accessor: "action",
  },
];

export const headersForRewardAccess = [
  {
    Header: "REWARD TYPE",
    accessor: "rewardType",
  },

  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "",
    accessor: "action",
  },
];

export const headersForTrendingList = (trendType) => {
  return [
    {
      Header: trendType === "article" ? "ARTICLES" : "VIDEOS",
      accessor: trendType === "article" ? "article" : "video",
    },

    {
      Header: "PUBLISHED DATE",
      accessor: "date",
    },
    {
      Header: "ENGAGEMENTS DISTRIBUTION",
      accessor: "engagementDistribution",
    },
    {
      Header: "ENGAGEMENT",
      accessor: "engagement",
    },
  ];
};

export const headersForMediaPage = [
  {
    Header: "REPRESENTATIVE",
    accessor: "representative",
  },

  {
    Header: "SUBSCRIBERS",
    accessor: "subscribers",
  },
  {
    Header: "LAST ACTIVE",
    accessor: "lastActive",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "COUNTRY",
    accessor: "country",
  },
  {
    Header: "ENGAGEMENTS",
    accessor: "engagements",
  },
  {
    Header: "",
    accessor: "action",
  },
];

export const headersForActivity = [
  {
    Header: "SUBSCRIBERS",
    accessor: "subscriber",
  },

  {
    Header: "EMAIL",
    accessor: "email",
  },

  {
    Header: "COMPANIES",
    accessor: "company",
  },
  {
    Header: "TIME",
    accessor: "time",
  },
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "EARNED GOLDIES",
    accessor: "earnedGoldies",
  },
];

export const makeDataToJsxShape = () => {
  return dataForMedia.map((dat) => {
    return {
      representative: (
        <CompanyWithSubs
          avatar={dat.representative.avatar}
          agency={dat.agency}
          name={dat.representative.name}
          isAgencyShow={true}
          navigateTo="/company-detail/29032"
        />
      ),
      subscribers: <Subscribers subscribers={dat.subscribers} />,
      country: <span className="low">{dat.country}</span>,
      status: <Status status={dat.status} />,
      lastActive: <span className="low">{dat.lastActive}</span>,
      enagagements: (
        <Badge
          type={dat.enagagements.type}
          amount={dat.enagagements.amount}
          style={{ padding: "7px 10px" }}
          isCompany={true}
        />
      ),
      action: <ActionBtns status={dat.status} canEdit={true} />,
    };
  });
};

export const makeDataForMediaAgency = (data, handleActionType) => {
  return data.map((dat) => {
    return {
      representative: (
        <CompanyWithSubs
          avatar={dat.avatar}
          agency={dat.agency}
          name={dat.representativeName}
          isAgencyShow={true}
          navigateTo={`/company-detail/${dat?.agencyId}/${dat?.agency}`}
        />
      ),
      subscribers: <Subscribers subscribers={dat.subscribers} />,
      country: (
        <span className="low">{makeFirtLetterCapital(dat.country)}</span>
      ),
      status: <Status status={dat.status} />,
      lastActive: <LastActive lastActive={dat.lastActivity} />,
      engagements:
        dat?.engagements > 0 ? (
          <Badge
            type={dat.engagementType}
            amount={dat.engagements}
            style={{ padding: "7px 10px" }}
            isCompany={true}
          />
        ) : (
          "--"
        ),
      action: (
        <ActionBtns
          status={dat.status}
          id={dat.agencyId}
          toggle={handleActionType}
          canEdit={true}
        />
      ),
    };
  });
};

export const dataShapeForCompany = (
  data,
  handleNavigation,
  toggle,
  isEmailUnder
) => {
  return data?.map((dat) => {
    return {
      subscriber: (
        <CompanyWithSubs
          isEmailUnder={false}
          name={dat?.subsName}
          isAgencyShow={false}
          email={dat?.email}
          isNavigate={isEmailUnder}
          avatar={dat?.subscriberImg}
          navigateTo={() => handleNavigation(dat)}
        />
      ),
      time: <span className="low">{dat?.time}</span>,
      status: <Status status={dat?.status} />,
      earnedGoldies: (
        <span className="low">{IntoComma(dat?.earnedGoldies)}</span>
      ),
      country: <span className="low">{dat?.country}</span>,
      engagements: <CompanyEngagements engagements={dat?.engagements} />,
      action: (
        <ActionBtns
          status={dat.status}
          toggle={() =>
            toggle(dat, dat?.status === "ACTIVE" ? "Inactive" : "Active")
          }
          forSubscribers={true}
        />
      ),
    };
  });
};

export const dataShapeForActivityLog = (data, navigateTo) => {
  return data?.map((dat) => {
    return {
      subscriber: (
        <CompanyWithSubs
          avatar={dat?.avatar}
          name={dat?.representative}
          isAgencyShow={false}
          navigateTo={navigateTo}
        />
      ),
      email: <span className="low">{dat?.email}</span>,
      earnedGoldies: (
        <span className="low">{IntoComma(dat.earnedGoldies)}</span>
      ),
      company: <span className="low">{dat.company}</span>,
      time: <span className="low">{dat.time}</span>,
      type: (
        <Badge
          type={dat.badgeType}
          style={{ padding: "5px 10px" }}
          isCompany={false}
        />
      ),
    };
  });
};

export const dataShapeForTrendingList = (data, type, navigateTo) => {
  return data?.map((dat) => {
    return {
      [type]: (
        <CompanyWithSubs
          isVideoArticle={true}
          defaultSrc={
            type === "video"
              ? "/assets/icons/videoDefault.svg"
              : "/assets/icons/articleDefault.svg"
          }
          isNavigate={false}
          avatar={dat?.imgSrc}
          name={dat?.title}
          isAgencyShow={false}
          navigateTo={navigateTo}
        />
      ),
      date: <span className="low">{dat?.publishedDate}</span>,
      engagementDistribution: (
        <CompanyEngagements engagements={dat?.engagementsDistribution} />
      ),
      engagement:
        dat?.engagement > 0 ? (
          <Badge
            type={dat.engagementType}
            amount={dat.engagement}
            style={{ padding: "7px 10px" }}
            isCompany={true}
          />
        ) : (
          "--"
        ),
    };
  });
};

export const dataShapeForRewardAccess = (data, handlePopup) => {
  return data?.map((dat) => {
    return {
      rewardType: <span className="low">{dat?.key}</span>,
      status: <Status status={dat?.status ? "active" : "inactive"} />,
      action: (
        <ActionBtns
          status={dat.status ? "ACTIVE" : "INACTIVE"}
          toggle={() => handlePopup(dat)}
          forSubscribers={true}
        />
      ),
    };
  });
};

export const imgsSrcOfGoldies = {
  Likes: "/assets/icons/likesBg.svg",
  Like: "/assets/icons/likesBg.svg",
  Comment: "/assets/icons/commentBg.svg",
  Comments: "/assets/icons/commentBg.svg",
  Share: "/assets/icons/shareBg.svg",
  Watch: "/assets/icons/watchBg.svg",
  View: "/assets/icons/viewBg.svg",
  Referral: "/assets/icons/referal.svg",
  Registration: "/assets/icons/register.svg",
  "Total Earned Goldies": "/assets/icons/earnedGoldie.svg",
  "Goldies Balance": "/assets/icons/goldieBalance.svg",
  "Last Month Credit": "/assets/icons/lastCredit.svg",
  "Transfer Money": "/assets/icons/monTransfer.svg",
};

const checkPasswordLength = (password) => {
  return password?.length < 8;
};

const checkEmailValid = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return !emailRegex.test(email);
};

function isNotInteger(value) {
  return !Number.isInteger(parseInt(value));
}

export const signInFields = {
  email: {
    value: "",
    error: "",
    required: true,
    name: "email",
    type: "email",
    regex: checkEmailValid,
    isRequireError: false,
    isInvalidError: false,
    label: "E-mail",
    placeholder: "Enter your email",
    requireError: "Enter your email",
    invalidError: "Email is invalid.",
  },
  password: {
    error: "",
    value: "",
    required: true,
    name: "password",
    type: "password",
    label: "Password",
    isRequireError: false,
    isInvalidError: false,
    showChangeTxt: true,
    changeTxt: "Forget Password?",
    regex: checkPasswordLength,
    placeholder: "Enter your password",
    requireError: "Enter your password",
    invalidError: "Must be 8 characters at least.",
  },
};

const dummyFunc = () => {
  return false;
};

export const addMediaCompanyFields = (mediaDetail, editPopup) => {
  return {
    representiveName: {
      value: mediaDetail?.representiveName ?? "",
      error: "",
      required: true,
      name: "representiveName",
      type: "text",
      regex: dummyFunc,
      isRequireError: false,
      isInvalidError: false,
      label: "Representative Name",
      placeholder: "Representative Name",
      requireError: "Please enter representative name.",
      invalidError: "",
    },
    mediaCompany: {
      error: "",
      value: mediaDetail?.mediaCompany ?? "",
      required: true,
      name: "mediaCompany",
      type: "text",
      label: "Media Company",
      isRequireError: false,
      isInvalidError: false,
      regex: dummyFunc,
      placeholder: "Media Company",
      requireError: "Please enter media company.",
      invalidError: "",
    },

    country: {
      error: "",
      required: true,
      name: "country",
      type: "cSelect",
      regex: dummyFunc,
      isRequireError: false,
      isInvalidError: false,
      value: editPopup
        ? {
            label: makeFirtLetterCapital(mediaDetail?.country),
            value: mediaDetail?.country,
          }
        : "",
      options: countries,
      label: "Country",
      placeholder: "Country",
      requireError: "Please enter country.",
      invalidError: "",
    },
    email: {
      value: mediaDetail?.email ?? "",
      error: "",
      required: true,
      name: "email",
      type: "email",
      regex: checkEmailValid,
      isRequireError: false,
      isInvalidError: false,
      label: "Email",
      disabled: !editPopup ? false : true,
      placeholder: "Email",
      requireError: "Please enter your email.",
      invalidError: "Email is invalid.",
    },
  };
};

export const fieldsForRewardConfiguration = (data, type) => {
  console.log({ type });
  return {
    [`watch${type}`]: {
      required: true,
      name: `watch${type}`,
      value: data["watch"],
      invalidError: "Should be a number.",
      error: "",
      type: "text",
      isRequireError: false,
      requireError: "Please enter watch goldies.",
      label: "Watch",
      isInvalidError: false,
      placeholder: "Input here",
      regex: isNotInteger,
    },
    [`like${type}`]: {
      value: data["like"],
      error: "",
      placeholder: "Input here",
      name: `like${type}`,
      type: "text",
      invalidError: "Should be a number.",
      isRequireError: false,
      required: true,
      regex: isNotInteger,
      requireError: "Please enter like goldies.",
      isInvalidError: false,
      label: "Like",
    },
    [`share${type}`]: {
      required: true,
      name: `share${type}`,
      type: "text",
      error: "",
      invalidError: "Should be a number.",
      regex: isNotInteger,
      isInvalidError: false,
      label: "Share",
      value: data["share"],
      placeholder: "Input here",
      isRequireError: false,
      requireError: "Please enter share goldies.",
    },
    [`comment${type}`]: {
      requireError: "Please enter comment goldies.",
      error: "",
      required: true,
      name: `comment${type}`,
      isInvalidError: false,
      value: data["comment"],
      regex: isNotInteger,
      isRequireError: false,
      type: "text",
      placeholder: "Input here",
      label: "Comment",
      invalidError: "Should be a number.",
    },
  };
};

export const fieldsForRefferal = (data) => {
  console.log({ data });
  return {
    referral: {
      required: true,
      name: `referral`,
      value: data?.referral,
      invalidError: "Should be a number.",
      error: "",
      type: "text",
      isRequireError: false,
      requireError: "Please enter referral goldies.",
      label: "Referral",
      isInvalidError: false,
      placeholder: "Input here",
      regex: isNotInteger,
    },
    register: {
      required: true,
      name: `register`,
      value: data?.register,
      invalidError: "Should be a number.",
      error: "",
      type: "text",
      isRequireError: false,
      requireError: "Please enter registration goldies.",
      label: "Registration",
      isInvalidError: false,
      placeholder: "Input here",
      regex: isNotInteger,
    },
  };
};

export const keyFields = (keyType, val) => {
  const fieldKey = keyType === "Private" ? "password" : "PublicKey";
  return {
    [fieldKey]: {
      value: val,
      error: "",
      readonly: true,
      disabled: false,
      name: fieldKey,
      isRequireError: false,
      isInvalidError: false,
      label: `${keyType} Key`,
      type: keyType === "Public" ? "text" : "password",
    },
  };
};

export const accountFieldsForAdmin = (data) => {
  return {
    firstName: {
      value: data?.firstName,
      error: "",
      required: true,
      name: "firstName",
      type: "text",
      regex: dummyFunc,
      isRequireError: false,
      isInvalidError: false,
      label: "First Name",
      placeholder: "Enter your first name",
      requireError: "Please enter first name.",
      invalidError: "",
    },
    lastName: {
      value: data?.lastName,
      error: "",
      required: true,
      name: "lastName",
      type: "text",
      regex: dummyFunc,
      isRequireError: false,
      isInvalidError: false,
      label: "Last Name",
      placeholder: "Enter your last name",
      requireError: "Please enter last name.",
      invalidError: "",
    },
    email: {
      value: data?.email,
      error: "",
      required: true,
      name: "email",
      type: "email",
      regex: checkEmailValid,
      isRequireError: false,
      isInvalidError: false,
      label: "Email",
      disabled: true,
      showChangeTxt: true,
      changeTxt: "Change Password?",
      placeholder: "Enter your email",
      requireError: "Please enter your email.",
      invalidError: "Email is invalid.",
    },
  };
};

export const accountFieldsForAgency = (data) => {
  return {
    representativeName: {
      value: data?.representativeName,
      error: "",
      required: true,
      name: "representativeName",
      type: "text",
      regex: dummyFunc,
      isRequireError: false,
      isInvalidError: false,
      label: "Representative Name",
      placeholder: "Enter your representative name",
      requireError: "Please enter representative name.",
      invalidError: "",
    },
    mediaCompany: {
      value: data?.mediaCompany,
      error: "",
      required: true,
      name: "mediaCompany",
      type: "text",
      regex: dummyFunc,
      disabled: true,
      isRequireError: false,
      isInvalidError: false,
      label: "Media Company",
      placeholder: "Enter your media company",
      requireError: "Please enter media company.",
      invalidError: "",
    },
    email: {
      value: data?.email,
      error: "",
      required: true,
      name: "email",
      type: "email",
      regex: checkEmailValid,
      isRequireError: false,
      isInvalidError: false,
      label: "Email",
      disabled: true,
      showChangeTxt: true,
      changeTxt: "Change Password?",
      placeholder: "Enter your email",
      requireError: "Please enter your email.",
      invalidError: "Email is invalid.",
    },
  };
};

export const changePasswordFields = {
  currentPassword: {
    error: "",
    value: "",
    required: true,
    name: "currentPassword",
    type: "password",
    label: "Current Password",
    isRequireError: false,
    isInvalidError: false,
    regex: checkPasswordLength,
    placeholder: "Current Password",
    requireError: "Please enter current password.",
    invalidError: "Must be 8 characters at least",
  },
  newPassword: {
    label: "New Password",
    value: "",
    required: true,
    name: "newPassword",
    isRequireError: false,
    invalidError: "Must be 8 characters at least.",
    type: "password",
    requireError: "Please enter new password.",
    error: "",
    placeholder: "New Password",
    isInvalidError: false,
    regex: checkPasswordLength,
  },
  confirmPassword: {
    invalidError: "Must be 8 characters at least.",
    name: "confirmPassword",
    type: "password",
    error: "",
    label: "Confirm Password",
    requireError: "Please enter confirm password.",
    value: "",
    isRequireError: false,
    isInvalidError: false,
    required: true,
    regex: checkPasswordLength,
    placeholder: "Confirm Password",
  },
};

export const getColor = (state) => {
  let color;
  if (state.isFocused) {
    color = "#fff";
  } else if (state.isSelected) {
    color = "#FFFFFF";
  } else {
    color = "#425466";
  }
  return color;
};

export const selectStyles = (width = "148px") => {
  return {
    singleValue: (provided) => ({
      ...provided,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "13px",
      lineHeight: "13px",
      color: "#B5B5BD",
    }),
    control: (provided, state) => ({
      ...provided,
      borderRadius: "6px",

      borderColor: "#ededed",
      "&:hover": {
        borderColor: "#fa1c3c",
      },
      boxShadow: state.isFocused ? "#fa1c3c" : "none",

      width,
    }),
    menu: (provided) => ({
      ...provided,
      padding: "14px 12px 10px 12px",
      borderRadius: "8px",
      background: "#FFFFFF",
      boxShadow:
        "0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05)",
    }),
    option: (provided, state) => ({
      ...provided,
      background: state.isSelected ? "#fa1c3c" : "",
      borderRadius: "6px",
      padding: "10px 12px",
      backgroundColor: state.isFocused ? "#fa1c3c" : "white",

      ":hover": {
        backgroundColor: "#fa1c3c",
        color: "#fff",
      },
      marginBottom: "5px",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "14px",
      color: getColor(state),
    }),
  };
};

export const forgetPasswordFields = {
  email: {
    value: "",
    error: "",
    required: true,
    name: "email",
    type: "email",
    regex: checkEmailValid,
    isRequireError: false,
    isInvalidError: false,
    label: "E-mail",
    placeholder: "Enter your email",
    requireError: "Enter your email",
    invalidError: "Email is invalid.",
  },
};

export const adminSeries = (dataStats) => {
  return dataStats?.map((dat) => {
    if (dat?.name === "Agencyonboarding") {
      return {
        name: "Media Companies",
        data: dat?.data,
      };
    } else
      return {
        name: "Subscribers",
        data: dat?.data,
      };
  });
};

export const agencySeriesLineChart = (dataStats) => {
  let arr = [];
  dataStats?.forEach((dat) => {
    if (dat?.name === "Subscriberonboarding") {
      arr.push({
        name: "Subscribers",
        data: dat?.data,
      });
    }
  });
  return arr;
};

export const dateFilterOptsDashboard = [
  { label: "This Year", value: "year" },
  { label: "This Month", value: "month" },
  { label: "This Week", value: "week" },
];

export const getStartANdEndDate = (timeframe) => {
  const startDate = moment().startOf(timeframe).format("YYYY-MM-DD");
  const newEndDate = moment().endOf(timeframe);
  const endDate = newEndDate.add(1, "days").format("YYYY-MM-DD");

  return {
    startDate,
    endDate,
  };
};

export const getTimeStringByTimeFrame = {
  year: "month",
  month: "week",
  week: "day",
};

export const typeFilterOptionsForMedia = [
  { label: "All", value: "ALL" },
  { label: "Recent", value: "RECENT" },
  { label: "High Performance", value: "HIGH_PERFORMING" },
  { label: "Low Performance", value: "LOW_PERFORMING" },
];

export const typeFilterOptionsForSubscribers = [
  { label: "All", value: "ALL" },
  { label: "Engaged", value: "ENGAGED" },
  { label: "Most Active", value: "MOST_ACTIVE" },
  { label: "Not Active", value: "NOT_ACTIVE" },
  { label: "Created at Ascending", value: "CREATED_AT_ASCENDING" },
  { label: "Created at Descending", value: "CREATED_AT_DESCENDING" },
];
export const typeFilterOptionsForTrendings = [
  { label: "Most Trending to Least Trending", value: "MOST_TRENDING" },
  { label: "Least Trending to MOST Trending", value: "LEAST_TRENDING" },
];

export const showFilterOptionsForMedia = [
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
];

export const typeFilterOptionsForActivity = [
  { label: "All", value: "All" },
  { label: "Like", value: "LIKE" },
  { label: "Share", value: "SHARE" },
  { label: "Comment", value: "COMMENT" },
  { label: "Watch", value: "WATCH" },
  { label: "View", value: "VIEW" },
  { label: "Registration", value: "REGISTRATION" },
  { label: "Referral", value: "REFERRAL" },
];

export const optionsForOnboard = [
  { label: "All", value: "All" },
  { label: "This Day", value: "day" },
  { label: "This Week", value: "week" },
  { label: "This Month", value: "month" },
];

export const dummyBoxData = [
  {
    goldiesType: "Referral",
    count: 17500,
    goldiesSource: "Referral",
    subscribers: 80,
  },
  {
    goldiesType: "Registration",
    count: 17500,
    goldiesSource: "Registration",
    subscribers: 80,
  },
];
