import React from "react";
import Select from "react-select";
import { selectStyles } from "../../utilities/static";
import CustomIndicator from "./CustomIndicator";
import "./styles.scss";

import { selectStylesForMedia } from "../../utilities/mock/mock";
const RSelect = ({
  sLabel,
  width,
  onChange,
  value,
  options,
  className,
  isError = false,
  isSearchable = false,
  showPortal = true,
}) => {
  const CustomIndicatorSeparator = () => {
    return null;
  };

  const portalProps = showPortal
    ? {
        menuPortalTarget: document.body,
        menuPosition: "fixed",
      }
    : {};

  return (
    <div className="select-ctn flex" style={{ minHeight: "46px" }}>
      {sLabel && <div className="s-label ml-16">{sLabel}</div>}
      <Select
        value={value}
        // defaultValue={isSearchable ? options[0] : ""}
        placeholder={isSearchable ? "Country..." : ""}
        className={`r-select ${className}`}
        isSearchable={isSearchable}
        styles={
          !isSearchable
            ? selectStyles(width)
            : selectStylesForMedia(width, isError)
        }
        options={options}
        onChange={(value) => onChange(value, sLabel)}
        components={{
          DropdownIndicator: CustomIndicator,
          IndicatorSeparator: CustomIndicatorSeparator,
        }}
        {...portalProps}
      />
    </div>
  );
};

export default RSelect;
