import { create } from "zustand";

const useStore = create((set) => ({
  loading: false,
  errorMessage: "",
  successMessage: "",
  imgError: null,
  currentPopupType: "",
  popup: {
    isError: false,
    isSuccess: false,
    message: "",
  },
  popupSrc: {
    "error!": "/assets/icons/redIcon.svg",
    successfully: "/assets/icons/greenIcon.svg",
  },
  setImgError: (message) => set((state) => ({ imgError: message })),
  sidebarIsOpen: false,
  setCurrentPopupType: (type) => set((state) => ({ currentPopupType: type })),
  setLoading: (bool) => set((state) => ({ loading: bool })),
  setPopup: (message) =>
    set((state) => ({
      popup:
        state.currentPopupType === "error"
          ? { isError: !state.popup.isError, isSuccess: false, message }
          : { isError: false, isSuccess: !state.popup.isSuccess, message },
    })),
  toggleSideBar: () =>
    set((state) => ({ sidebarIsOpen: !state.sidebarIsOpen })),
}));

export default useStore;
