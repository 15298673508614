import React, { useEffect, useState } from "react";
import TopBar from "../../hoc/TopBar";
import { useNavigate, useParams } from "react-router-dom";
import CustomBreadCrumb from "../../components/CustomBreadCrumb";
import { uniqWith, isEqual } from "lodash";
import TableBox from "../../components/TableBox";
import {
  dataShapeForTrendingList,
  headersForTrendingList,
  showFilterOptionsForMedia,
  typeFilterOptionsForTrendings,
} from "../../utilities/static";
import "./styles.scss";
import { fetchVideoArticle } from "../../apis/apis";
import useStore from "../../store/store";
import { throughError } from "../../utilities/functions/common";
import roleStore from "../../store/role-store";
import { parseVideoArticleData } from "../../utilities/functions/parsers";
import Loader from "../../components/Loader";

const TrendingListing = () => {
  const { type } = useParams();
  const crumbs = [
    {
      to: "/",
      txt: "Dashboard",
      active: false,
    },
    {
      to: "",
      txt: type === "article" ? "Articles" : "Videos",
      active: false,
    },
  ];
  const [showFilter, setShowFilter] = useState(showFilterOptionsForMedia[0]);
  const [sortByFilter, setSortByFilter] = useState(
    typeFilterOptionsForTrendings[0]
  );

  const { loading, setLoading } = useStore();
  const [trendingData, setData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [paginationInfo, setPaginationInfo] = useState({ limit: 10 });
  const [currentPage, setCurrentPage] = useState(0);
  const { loggedUser } = roleStore();

  let navigate = useNavigate();

  useEffect(() => {
    getTrendingList(showFilter?.value, sortByFilter?.value, searchValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageChange = (data) => {
    let loPage = 1;
    setCurrentPage(data?.selected);
    getTrendingList(
      showFilter?.value,
      sortByFilter?.value,
      searchValue,
      loPage + +data?.selected
    );

    setPaginationInfo((prev) => {
      return {
        ...prev,
        page: loPage + +data?.selected,
      };
    });
  };

  const getTrendingList = (show, sortBy, search, page = 1) => {
    const params = {
      show,
      sortBy,
      search,
      page,
      id: loggedUser?._id,
      contentType: `${type?.toUpperCase()}`,
    };
    setLoading(true);
    fetchVideoArticle(params)
      .then((response) => {
        const { count, totalPages, page, data, limit } = response?.data?.data;
        const parseContent = parseVideoArticleData(data, type);
        setData(parseContent);
        setPaginationInfo({
          count,
          page: +page,
          limit: +limit,
          pageCount: totalPages,
        });
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        throughError(err, navigate);
      });
  };

  const handleSaveFilterVal = (value, type) => {
    setCurrentPage(0);
    if (type === "Show") {
      setShowFilter(value);
      getTrendingList(value?.value, sortByFilter?.value, searchValue);
    } else {
      setSortByFilter(value);
      getTrendingList(showFilter?.value, value?.value, searchValue);
    }
  };

  const onSearchChange = (e) => {
    setSearchValue(e.target.value);
    getTrendingList(showFilter?.value, sortByFilter?.value, e.target.value);
  };

  const LocalPaginationInfo = {
    currentPage,
    ...paginationInfo,
    currentLenghtOfRecord: trendingData?.length,
  };

  const navigateTo = () => {
    return;
  };

  return (
    <div className="trending-list">
      <TopBar>
        <div>
          <h1>{type === "article" ? "Articles" : "Videos"}</h1>
          <CustomBreadCrumb crumbs={uniqWith(crumbs, isEqual)} />
        </div>
      </TopBar>

      <div className="inner-ctn">
        <TableBox
          width="260px"
          enablePagination={true}
          searchValue={searchValue}
          optionValue1={showFilter}
          optionValue2={sortByFilter}
          onPageChange={onPageChange}
          onSearchChange={onSearchChange}
          paginationInfo={LocalPaginationInfo}
          options1={showFilterOptionsForMedia}
          headers={headersForTrendingList(type)}
          options2={typeFilterOptionsForTrendings}
          handleSaveFilterVal={handleSaveFilterVal}
          data={dataShapeForTrendingList(trendingData, type, navigateTo)}
        />
      </div>

      <Loader loading={loading} />
    </div>
  );
};

export default TrendingListing;
