const GET_URL = () => {
  const { REACT_APP_ENV } = process.env;
  let url = "";
  switch (REACT_APP_ENV.trim()) {
    case "dev":
      url = "https://api-dev.pact.synavos.net";
      break;
    case "qa":
      url = "https://api-qa.pact.synavos.net";
      break;
    case "staging":
      url = "https://api-staging.pact.synavos.net";
      break;
    case "production":
      url = "https://api.spreado.co";
      break;
    case "local":
      url = "http://localhost:3001";
      break;
    default:
      break;
  }

  return url;
};

export const API_SERVER_URL = GET_URL();
